import React, { useState, useRef, useEffect } from 'react';
import PageHeader from '../PageHeader/PageHeader';
import './ToolDetail.css'
import heroImage from '../../assets/images/about/about-business-bloc.png';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import toolImageBusinessDashboard from '../../assets/images/tools/tool-business-dashboard.png';
import DOMPurify from "dompurify";

interface Params {
  tool_unique_id: string
}


const ToolDetail: React.FC = () => {
  const { tool_unique_id } = useParams();
  const [toThisTool, setToThisTool] = useState<any>(undefined);
  const [loadPageAssets, setLoadPageAssets] = useState(false);
  const [plans, setPlans] = useState({
    essential: false,
    growth: false,
    complete: false
  });

  const business_claimed_id = new URLSearchParams(window.location.search).get('business_claimed_id');

  let businessBlocAuthToken = localStorage.getItem("business_bloc_token")



  let params: Params = {
    tool_unique_id: tool_unique_id || "g"
  }
  

  async function findSupportTickets() {
    await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + '/tool/retrieve-details', {
      params
    }).then((retrieveSupportTickets: any) => {
      if(retrieveSupportTickets.data.tool.length != 0){
        setToThisTool(retrieveSupportTickets.data.tool)
        setPlans({
          essential: toThisTool.partOfPlan === 'essential' || toThisTool.partOfPlan === 'growth' || toThisTool.partOfPlan === 'complete',
          growth: toThisTool.partOfPlan === 'growth' || toThisTool.partOfPlan === 'complete',
          complete: toThisTool.partOfPlan === 'complete'
        });


        // Hours Of Operation
        //setToThisBusinessHoursOfOperation(businessHoursOfOperations)
        
        // Images
        //setToThisBusinessImages(parsedBusinessDirectory[0].business_images)
        //const urlToSave =  process.env.REACT_APP_BLOC_SERVER_URL + parsedBusinessDirectory[0].hero_image.url
        //setToThisBusinessHeroImage(urlToSave);
        
        // Categories
        //setToThisBusinessCategory(parsedBusinessDirectory[0].business_categories[0].category_name.replaceAll('-', ' '))
                        
        // Address
        /*const streetAddressObject = parsedAddress.find((obj: any) => obj.street_address !== undefined);
        setToThisBusinessAddress(streetAddressObject.street_address)
        const cityObject = parsedAddress.find((obj: any) => obj.city !== undefined);
        const stateObject = parsedAddress.find((obj: any) => obj.state !== undefined);
        const countryObject = parsedAddress.find((obj: any) => obj.country !== undefined);
        setToThisBusinessGoogleMapAddress(streetAddressObject.street_address + ", " + cityObject.city + ", " + stateObject.state + ", " + countryObject.country)
        
        // Social media
        setToThisBusinessSocialMedia(parsedSocialMedia)*/
      }
    })
  }

  //const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(toThisBusinessGoogleMapAddress)}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`;

  React.useEffect(() => {
    findSupportTickets().then(() => {
      setLoadPageAssets(true)
    })
    //const businessPhone = toThisBusiness
  }, [])

  let sanitizedDescription = ""
  let message
  let planName
  let iconClass = "";

  if (toThisTool != undefined) {

   sanitizedDescription = DOMPurify.sanitize(toThisTool.content);


   // PArt of plan
   let partOfPlan = toThisTool.part_of_plan
   
   if (partOfPlan.includes("free")) {
     message = "Available for free use for everyone";
     iconClass = "fa fa-check"; // Check icon for free
     planName = 'free';
   } else if (partOfPlan.includes("web-hosting") && partOfPlan.includes("complete")) {
     message = "Available to all plan members";
     iconClass = "fa fa-shield"; // Check icon for free
     planName = 'all-plans';
   } else if (partOfPlan.includes("web-hosting")) {
     message = "Web hosting required";
     iconClass = "fa fa-server"; // Check icon for free
     planName = 'web-hosting';
   } else if (partOfPlan.includes("complete")) {
     message = "Complete plan required";
     iconClass = "fa fa-shield"; // Check icon for free
     planName = 'complete';
   } else {
     message = "No specific plan details available";
   }


  }
  

  return (
    <>
    { toThisTool != undefined ?
    <div className="Tool-detail-page">
      <PageHeader pageTitle={toThisTool.name} pageImage={""} pageDescription={toThisTool.subtitle} template="tools-single"/>
      <section className="Page">
        <div className="Page-content">
          <div className="Page-content-70">
            <div className="Page-content-inner">
              <div
                className="tool-description"
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
              />
              
            </div>
          </div>
          <div className="Page-content-30">
            <img 
              src={
                toThisTool.tool_images && toThisTool.tool_images.length > 0
                  ? process.env.REACT_APP_BLOC_SERVER_URL + toThisTool.tool_images[0].url
                  : '/path/to/fallback/image.jpg'  // Provide a fallback image or handle the case
              } 
              alt="Tool Image"
            />
            <div className="Page-content-inner">
              <h3>Requirements:</h3>
              { toThisTool != undefined ?
              <p className={`plan-detail ${planName}`}><i className={iconClass}></i> {message}</p>            
              : "" }
              <h4><i className="fa fa-building"></i> Tool managed by Business Bloc</h4>
            </div>
          </div>
        </div>
      </section>
    </div>
    : "" }
    </>
  );
};

export default ToolDetail;
