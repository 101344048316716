import React from 'react';
import PageHeader from '../PageHeader/PageHeader'
import './Referral.css'
import heroImage from '../../assets/images/referral-program/business-bloc-referral-program.png';
import { Helmet } from 'react-helmet-async';
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
  business_website_url: string
}
const initialValues: FormValues = {
  business_name: '',
  business_email: '',
  business_password: '',
  business_website_url: ''
}
const validationSchema = Yup.object().shape({
  business_name: 
    Yup.string()
    .min(2, "Your business name must be at least 2 characters.")
    .max(50, "Your businss name must be a maximum of 50 characters.")
    .matches(/^[\w ]*[^\W_][\w ]*$/, "Your business name may only contain letters and numbers.")
    .required("You must provide a business name that is between 2-50 characters."),
  business_website_url: 
    Yup.string()
    .min(3, "Your website URL must be a minimum of 2 characters.")
    .max(70, "Your website URL must be a maximum of 70 characters.")
    .matches(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/, "Your website URL must contain an extension such as \"example.com\".")
    .required("You must provide a website url that is between 3-70 characters."),
  business_email: 
    Yup.string()
    .email("You must provide an email address such as \"example@gmail.com\"")
    .min(5, "Your email address must be a minimum of 5 characters.")
    .max(60, "Your email address must be a maximum of 60 characters.")
    .required("You must provide an email address that is between 5-60 characters."),
  business_password: Yup.string()
    .min(8, "Your password must be a minimum of 8 characters.")
    .max(35, "Your password must be a maximum of 35 characters.")
    .matches(/[a-zA-Z]/, 'Your password must contain supported language letters.')
    .matches(/[@$!%*#?&]+/, "Your password must be 8-35 characters and contain at least one number and special character.")
    .matches(/\d+/, "Your password must be 8-35 characters and contain at least one number and special character.")
    .required("Your password may only be between 8-35 characters and contain at least one number and special character."),
});
const Referral: React.FC = () => {
  const navigate = useNavigate();
  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/create', { values }).then((successAccountCreated) => {
      localStorage.setItem("business_bloc_token", successAccountCreated.data.token)   
      navigate("/checkout");
    })
  }
  return (
    <div className="Referral-page">
      <Helmet>
        <title>Referral Program - Business Bloc</title>
        <meta name="description" content="Refer other businesses to Business Bloc and earn rewards while helping them grow their digital presence." />
        <meta name="keywords" content="referral program, business referrals, partner program, Business Bloc rewards, business partnerships, grow your network, earn rewards" />
        <meta property="og:title" content="Referral Program - Business Bloc" />
        <meta property="og:description" content="Invite other businesses to Business Bloc and benefit from exclusive rewards as you grow your professional network." />
        <meta property="og:image" content={heroImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      <PageHeader pageTitle="Referral Program"  pageDescription="Earn money by referring friends to our all-in-one digital platform and helping them elevate their online presence." pageImage={heroImage} template=""/>
      <section className="Page">
        <div className="Page-content">
          <div className="Page-content-inner">
            <div className="Referral-info">
            <div className="referral-program">
                <h2>Referral Program Earnings</h2>
                <p>
                  Welcome to our Referral Program—a simple and fair way to earn recurring income. 
                  For every client you refer, you'll receive a monthly commission based on their chosen plan.
                  Help businesses succeed while building a passive income stream for yourself!
                </p>
                <p>Here’s how much you can earn:</p>
                <table className="referral-table">
                  <thead>
                    <tr>
                      <th>Plan Name</th>
                      <th>Monthly Cost</th>
                      <th>Commission Rate</th>
                      <th>Your Earnings per Referral (Monthly)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Website Design & Hosting</td>
                      <td>$15 / CAD</td>
                      <td>20%</td>
                      <td>$3.00 / CAD monthly*</td>
                    </tr>
                    <tr>
                      <td>Complete Dashboard Access</td>
                      <td>$45 / CAD</td>
                      <td>25%</td>
                      <td>$11.25 / CAD monthly*</td>
                    </tr>
                  </tbody>
                </table>
                <p className="legal-disclaimer">
                  <i>*Commission payments are issued only for active clients who maintain their monthly subscriptions. 
                  Referral commissions are subject to the terms and conditions of the program and comply with 
                  Alberta’s Fair Trading Act and applicable regulations.</i>
                </p>
                <p>
                  Join today and start earning! It's simple: refer businesses to our platform, 
                  and we'll reward you with recurring commissions as long as they stay subscribed.
                </p>
              </div>

            </div>
            <div className="Referral-program-form">
              <Formik
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                initialValues={initialValues}
              >
                <Form className="Referral-registration-form">
                  <div className="Referral-registration-form-inner">
                    <h2 className="affiliate-registration-heading">
                      <i className="fa fa-users"></i> 
                      <span>Register as an affiliate</span>
                    </h2>
                    <div className="Field-Container">
                      <ErrorMessage name="business_name" component="span" />
                      <Field type="text" placeholder="Business name" name="business_name" />
                    </div>
                    <div className="Field-Container">
                      <ErrorMessage name="business_email" component="span" />
                      <Field type="text" placeholder="Email" name="business_email"/>
                    </div>
                    <div className="Field-Container">
                      <ErrorMessage name="business_password" component="span" />          
                      <Field type="password" placeholder="Password" name="business_password"/>
                    </div>
                    <button type="submit">Register account</button>
                  </div>
                </Form>
              </Formik> 
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Referral;
