import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root') as HTMLElement;

if (container.hasChildNodes()) {
  // Hydrate the pre-rendered HTML (from react-snap) so that React attaches event listeners.
  hydrateRoot(container, <App />);
} else {
  // No pre-rendered content; create a new React root.
  createRoot(container).render(<App />);
}

reportWebVitals();
