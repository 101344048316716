import React from 'react';
import { Link } from 'react-router-dom';
import heroImage from '../../assets/images/business-bloc.jpg';
import './PageHeader.css'
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import * as Yup from "yup";

type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
  business_website_url: string
}


const PageHeader: React.FC <{ pageTitle: string, pageDescription: string, pageImage: string, template: string}> = ({ pageTitle, pageDescription, pageImage, template }) => {  

  return (
  <>
  { template == 'business-search' ?
    <section className="Page-Header" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${pageImage}), url(${pageImage})` }}>
      <div className="Page-Header-Main-Section">
        <div className="Page-Header-content">
            <h1>{pageTitle}</h1>
            <div className="divider"/>
            <h2>Leave your website in our capable hands, so you can focus on building your empire. We make digital maintenance a breeze - without breaking the bank!</h2>
        </div>
      </div>
      <div className="Page-Header-Search-For-Business">
        <i className="fas fa-search"></i>
        <div className="Page-Header-Search-Content">
          <span className="Dynamic-Business-Listing-Count">Over 1200+ businesses</span>
          <h2>Search for your business</h2>
          <input type="text" placeholder="Search for..."/>
          <select>
            <option>Website design</option>
          </select>
          <button>Start search</button>

        </div>
      </div>
    </section>
    : template == 'tools-main' ?
    <section className="Page-Header Tools" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${pageImage}), url(${pageImage})` }}>
      <div className="Page-Header-Main-Section-Full-Width">
        <div className="Page-Header-content-full-width">
            <h1>{pageTitle}</h1>
            <h2>{pageDescription}</h2>
        </div>
      </div>
    </section>
    : template == 'tools-single' ?
    <section className="Page-Header Tools-Single" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${pageImage}), url(${pageImage})` }}>
      <div className="Page-Header-Main-Section-Full-Width">
      <div className="Page-Header-content-full-width">

        <div className="Page-Header-content-70">
            <Link to="/business-tools"><i className="fa fa-arrow-left"></i> Return to tools</Link>
            <h1>{pageTitle}</h1>
            <h2>{pageDescription}</h2>
            <Link className="tool-cta-action-next-step" to="/get-started"><i className="fa fa-plus"></i> Get started</Link>
        </div>
        <div className="Page-Header-content-30">
        </div>
        </div>
      </div>
    </section>
    :
    <section className="Page-Header" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${pageImage}), url(${pageImage})` }}>
      <div className="Page-Header-Main-Section-Full-Width">
        <div className="Page-Header-content-full-width">
            <h1>{pageTitle}</h1>
            <div className="divider"/>
            <h2>{pageDescription}</h2>
        </div>
      </div>
    </section>
    }
    </>
  )
}

export default PageHeader;
