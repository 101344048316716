import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements, CardElement, Elements } from "@stripe/react-stripe-js";
import { useNavigate, Link } from "react-router-dom";
import { subscriptionPlanDetails } from './utils/config'
import {loadStripe} from '@stripe/stripe-js';
import './Checkout.css';
import selectPricingPlan from '../../assets/images/checkout/business-bloc-select-a-pricing-plan.png'
import BusinessListingForm from './BusinessListingForm';
import axios from 'axios';

interface subscriptionData {
  subscriptionId: string,
  clientSecret: string
}

const stripePromise = loadStripe(process.env.REACT_APP_BLOC_WEBSITE_STRIPE_PUBLISHABLE_KEY!);

const Checkout: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  useEffect(() => {
    const genBusinessBlocAuth = async () => {
      let businessBlocAuthToken = localStorage.getItem("business_bloc_token")
      await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + "/commerce/init-checkout", {
        headers: {
          "x-access-token": businessBlocAuthToken,
        },
      }).then( async(response: any) => { 
        const value = response.data.client_secret
        setClientSecret(value);
        console.log(value)
      })
    };
    //if(businessAuth.status == true){
      genBusinessBlocAuth();
    //} else {
     // window.location.href = '/login'
    //}
  }, []);

  return (
    <>
      { businessAuth.status == true && businessAuth.rank == 'registered' && appLoading == false ?
      <Elements stripe={stripePromise}>
        <MyComponent 
          businessAuth={businessAuth}
          appLoading={appLoading}
        />
      </Elements>
      : "" }
    </>
  );
}


const MyComponent: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const navigate = useNavigate();
  const [prices, setPrices] = useState("" as any);
    // Get the lookup key for the price from the previous page redirect.
  const [clientSecret] = useState('');
  const [subscriptionId] = useState('');
  const [name, setName] = useState('Jenny Rosen');
  const [error, setError] = useState<string | "">("");
  const [messages, setMessages] = useState('');
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [priceLookupKey, setPriceLookupKey] = useState<string>('price_1NS0JgJkpa2mwrtkEsHHfRso');
  const [subscriptionProcessingLoading, setSubscriptionProcessingLoading] = useState<boolean>(false);
  const [paymentIntent, setPaymentIntent] = useState();
  const [allowCreditCardFieldInput, setAllowCreditCardFieldInput] = useState<boolean | false>(false);
  let [displayPrice, setDisplayPrice] = useState('' as string || null); 
  let businessBlocAuthToken = localStorage.getItem("business_bloc_token")

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();
  
  const [subscriptionData, setSubscriptionData] = useState<subscriptionData>({
    subscriptionId: '',
    clientSecret: ''
  })
   
  const createSubscription = async (priceId: any) => {
    const genSubscription = async () => {
      setSubscriptionProcessingLoading(true)
      let currentAuthBusiness = businessAuth.email
      if(currentAuthBusiness != ''){
        await axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/commerce/create-subscription', {
          priceId,
          currentAuthBusiness
        }, {
          withCredentials: true,
        }
        ).then((subscriptionResponse: any) => {
          if(subscriptionResponse.status == 200){
            const subscriptionId = subscriptionResponse.data.subscriptionId
            const clientSecret = subscriptionResponse.data.clientSecret
            if(subscriptionId && clientSecret){
              setSubscriptionData({ subscriptionId, clientSecret });
              setAllowCreditCardFieldInput(true)
            } else {
              setAllowCreditCardFieldInput(false)
              console.log("Error: #908")
            }
          }          
        })
      } else {
        console.log("Not authorized")
      }
    }
    genSubscription().then(() => {
      setSubscriptionProcessingLoading(false)
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const cardElement = elements!.getElement(CardElement);
    // Use card Element to tokenize payment details
    const data = await stripe!.confirmCardPayment(subscriptionData.clientSecret, {
      payment_method: {
        card: cardElement!,
        billing_details: {
          name: name,
        }
      }
    }).then((response) => {
      if(response.paymentIntent?.status == 'succeeded'){
        axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/update-rank', { 
            referral_id: localStorage.getItem("referral_id")?.toString(),
            sender: businessAuth.entity,
            business_email: businessAuth.email, 
            business_rank_id: response.paymentIntent.amount 
          }, {
          withCredentials: true
        }).then((businessRankStatus) => {
          if(businessRankStatus.status == 200 && businessRankStatus.data.errorMessage == undefined){
            localStorage.setItem("business_bloc_token", businessRankStatus.data.token);
            // If referral ID exists, create referral in DB and remove from localStorage
            if(localStorage.getItem("referral_id")){
              localStorage.removeItem("referral_id")
            }
            window.location.href = "https://businessbloc.com/dashboard"
          }          
        })
      } else {
        setError(response.paymentIntent?.status || 'Error')
      }
    });
  }

  if(subscriptionData) {
    //navigate('/worked')
  }

  const handlefreeTierSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlan("free-tier")
  }

  const handlePlanSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlan(e.target.value);
    switch (e.target.value) {
      // Starter
      case 'price_1NS0JgJkpa2mwrtkEsHHfRso':
        setPriceLookupKey('price_1NS0JgJkpa2mwrtkEsHHfRso');
        setDisplayPrice("$45/CAD monthly")
        break;
      // Growth
      case 'price_1MzrWfJkpa2mwrtkGgs2YrZz':
        setPriceLookupKey('price_1MzrWfJkpa2mwrtkGgs2YrZz');
        setDisplayPrice("$130/CAD monthly")
        break;
      // Enterprise
      case 'price_1MzrYlJkpa2mwrtkprS4fQBN':
        setPriceLookupKey('price_1MzrYlJkpa2mwrtkprS4fQBN');
        setDisplayPrice("$275/CAD monthly")
        break;
      default:
        setPriceLookupKey('price_1NS0JgJkpa2mwrtkEsHHfRso');
        setDisplayPrice("$45/CAD monthly")
    }
    createSubscription(e.target.value)
  };

  return (
    <>
    { businessAuth.status == true && businessAuth.rank == 'registered' && appLoading == false ?

<div className="Checkout-Page">

    <section className="Checkout-Header">
      <div className="Checkout-Header-Main-Section">
        <div className="Checkout-Header-content">
  <div className="checkout-container">
    <div className="plans-container">
      <div className="plans-container-inner">

      <ul className="Checkout-Step-List">
                <li className="Checkout-Step-Indicator">Step 2 of</li>
                <li className="Checkout-Step-Final-Step">Step 2</li>
          </ul>
        <h2>Select a plan</h2>
        <p><strong>Please select from one of the following services.</strong> You can upgrade, downgrade and add new services later if your business requires.</p>
        <div key="4">
          <div className="Checkout-Service-Option">     
            <label>
              <span className="price">
                FREE
              </span>
              {/*<input
                type="checkbox"
                name="basic"
                value="free-tier"
                checked={selectedPlan === "free-tier"}
                onChange={handlefreeTierSelection}
              />*/}
              <a href="https://businessbloc.com/dashboard" >
                <>
                  Business dashboard, listing and access to free tools
                </>
              </a>
            </label>
            <span className="Service-Option-Description">List your business and products on Business Bloc and access our free tools. </span>
          </div>
        </div>
        <div key="5">
          <div className="Checkout-Service-Option">     
            <label>
              <span className="price">
                  $15/month CAD
              </span>
              <Link to="/">
                <>
                  <input
                    type="checkbox"
                    name="basic"
                    value="free-tier"
                  />
                  Website design and hosting
                </>
              </Link>
            </label>
            <span className="Service-Option-Description">Instantly create a website for your business includes monthly web hosting.</span>
          </div>
        </div>
        {Object.entries(subscriptionPlanDetails).map((price: any, i: number) => {
          return (
            <div key={price[1].id}>
              <div className="Checkout-Service-Option">     
              <span className="price">
                ${price[1].params?.unit_amount / 100}/month CAD
              </span>
                <label>
                  <input
                    type="checkbox"
                    name="basic"
                    value={price[1].params.lookup_key}
                    checked={selectedPlan === price[1].params.lookup_key}
                    onChange={handlePlanSelection}
                  />
                  {price[1].name}
                </label>
                <span className="Service-Option-Description">{price[1].description}</span>
              </div>
            </div>
          )
        })}

      </div>
    </div>
    <div className="Checkout-Form-Container">
      { selectedPlan != "free-tier" && (displayPrice != undefined && subscriptionProcessingLoading == false) ?
      <>
      <div className="Checkout-Cart-Total">
        { 
        selectedPlan == 'price_1NS0JgJkpa2mwrtkEsHHfRso' ?
          <>  
            <h2>Complete</h2>
            <p className="Checkout-Cart-Description">
              The complete subscription plan is perfect for businesses who require on-going website maintenance and require access to our industry-tailored dashboad to take your businesses' digital presence to the next level.
            </p>
          </>
        : "" }
        
        
        
        <span>Total cost: {displayPrice}</span>
      </div>
      <div className="Checkout-Price-Container">
      <h2>Checkout now</h2>
      <p>Payment secured with Stripe and SSL</p>
      { allowCreditCardFieldInput == true ?
      <form onSubmit={handleSubmit}>
        <CardElement />

        <div>{messages}</div>
        
        <button className="Checkout-Payment-Button" type="submit">Confirm payment</button>
      </form> 
      : "Please select a plan" }
      </div>
      </>
      : selectedPlan != 'free-tier' ?
      <>
        <div className="Checkout-Price-Container"  style={{ border: '5px solid #9561b2', backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${selectPricingPlan}), url(${selectPricingPlan})` }}>
          { subscriptionProcessingLoading == true ? <div className="Subscription-Loading">Loading...</div> : "" }
          <h2>Please select a pricing plan</h2> 
          <p>Don't worry, you can always change your plan later.</p>
        </div> 
      </>
      : 
        <div className="Business-Listing-Form-Container">
          <BusinessListingForm/>
        </div>
      }
    </div> 
  </div>
        </div>
      </div>
    </section>
  </div>

    





    : "" }
    </>
  );
}

export default Checkout;
