import React, { useState } from 'react';
import PageHeader from '../PageHeader/PageHeader'
import './Directory.css'
import heroImage from '../../assets/images/referral-program/business-bloc-referral-program.png';
import businessDirectoryMain from '../../assets/images/business-directory/business-directory.jpeg';
import { Helmet } from 'react-helmet-async';

import '../PageHeader/PageHeader.css'
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import BusinessItem from './BusinessItem';

interface BusinessItemProps {
  name: string;
  category: string;
  image: string;
  address: string;
  phoneNumber: string;
}

interface Params {
  entity: string
}

type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
  business_website_url: string
}
const initialValues: FormValues = {
  business_name: '',
  business_email: '',
  business_password: '',
  business_website_url: ''
}
const validationSchema = Yup.object().shape({
  business_name: 
    Yup.string()
    .min(2, "Your business name must be at least 2 characters.")
    .max(50, "Your businss name must be a maximum of 50 characters.")
    .matches(/^[\w ]*[^\W_][\w ]*$/, "Your business name may only contain letters and numbers.")
    .required("You must provide a business name that is between 2-50 characters."),
  business_website_url: 
    Yup.string()
    .min(3, "Your website URL must be a minimum of 2 characters.")
    .max(70, "Your website URL must be a maximum of 70 characters.")
    .matches(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/, "Your website URL must contain an extension such as \"example.com\".")
    .required("You must provide a website url that is between 3-70 characters."),
  business_email: 
    Yup.string()
    .email("You must provide an email address such as \"example@gmail.com\"")
    .min(5, "Your email address must be a minimum of 5 characters.")
    .max(60, "Your email address must be a maximum of 60 characters.")
    .required("You must provide an email address that is between 5-60 characters."),
  business_password: Yup.string()
    .min(8, "Your password must be a minimum of 8 characters.")
    .max(35, "Your password must be a maximum of 35 characters.")
    .matches(/[a-zA-Z]/, 'Your password must contain supported language letters.')
    .matches(/[@$!%*#?&]+/, "Your password must be 8-35 characters and contain at least one number and special character.")
    .matches(/\d+/, "Your password must be 8-35 characters and contain at least one number and special character.")
    .required("Your password may only be between 8-35 characters and contain at least one number and special character."),
});
const Referral: React.FC = () => {
  const businessesPerPage = 12;

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);  
  const [uniqueCategories, setUniqueCategories] = useState<any>([]);

  

  const [loadPageAssets, setLoadPageAssets] = useState(false);
  const params: Params = {
    entity: ""
  }
  


  const [suggestion, setSuggestion] = useState('');
  const [liveConversation, setLiveConversation] = useState<any>([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState<any>([]);
  const [originalBusinesses, setOriginalBusinesses] = useState<any>([]); // Original list of businesses
  const [selectedCategory, setSelectedCategory] = useState('');

  async function findSupportTickets() {
    await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + '/business/directory/retrieve', {
      params, 
    }).then((retrieveSupportTickets: any) => {
      if(retrieveSupportTickets.data.length != 0){
        setOriginalBusinesses(retrieveSupportTickets.data)
        setFilteredBusinesses(retrieveSupportTickets.data)
        // Categories
        const originalBusinesses = retrieveSupportTickets.data;

        // Create a set to store unique categories
        const uniqueCategoriesSet = new Set();
      
        // Function to convert category_name to the desired format
        const formatCategoryName = (categoryName: any) => {
          return {
            name: categoryName.replace(/[_-]/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase()),
            slug: categoryName.replace(/_/g, '-').toLowerCase()
          };
        };
        
        // Iterate over each business
        originalBusinesses.forEach((business: any) => {
          // Check if the business has the 'business_categories' property
          if (business.business_directory && business.business_directory.length > 0) {
            const businessDirectory = business.business_directory

            //const businessCategoriesItem = JSON.parse(businessDirectory.business_categories)
            if(businessDirectory){
              const businessCategories = businessDirectory[0].business_categories;
              //console.log(businessCategories)
        
              // Check if business_categories is an array
              if (Array.isArray(businessCategories)) {
                // Iterate over each category in the business
                businessCategories.forEach((category) => {
                  // Add formatted category to the set
                  if (category.category_name) {
                    uniqueCategoriesSet.add(formatCategoryName(category.category_name));
                  }
                });
              }
            }
          }
        });
      
        // Convert the set to an array
        const uniqueCategories = Array.from(uniqueCategoriesSet);
      
        // Log or do something with the unique categories array
        if(uniqueCategories != null){
          setUniqueCategories(uniqueCategories)
        }

      }
    })
  }

  React.useEffect(() => {
    findSupportTickets().then(() => {
      setLoadPageAssets(true)
    })
  }, [])

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 10);
  };

  const handleSearch = () => {
    const term = searchTerm.toLowerCase().trim();

    // Filter the original list of businesses
    let filtered = originalBusinesses.filter((business: any) =>
      business.business_name.toLowerCase().includes(term)
    );

    if (selectedCategory) {
      filtered = filtered.filter((business: any) =>
        business.business_directory[0]?.business_categories.some(
          (category: any) => category.category_name.toLowerCase() === selectedCategory
        )
      );
    }

    setFilteredBusinesses(filtered);
    setSelectedCategory('');
    setCurrentPage(1);
    scrollToTop();
  };

  const indexOfLastBusiness = currentPage * businessesPerPage;
  const indexOfFirstBusiness = indexOfLastBusiness - businessesPerPage;
  const currentBusinesses = filteredBusinesses.slice(
    indexOfFirstBusiness,
    indexOfLastBusiness
  );

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    scrollToTop(); // Scroll to the top when changing the page
  };
  
  const navigate = useNavigate();

  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/create', { values }).then((successAccountCreated) => {
      localStorage.setItem("business_bloc_token", successAccountCreated.data.token)   
      navigate("/checkout");
    })
  }
  
  const businessLocations = [{
    "state": "Alberta",
    "slug": "alberta",
    "cities": [
      {
        "name": "Barrhead",
        "slug": "barrhead"
      }
    ]
  }]

  const handleCategoryClick = (categorySlug: string) => {
    if(categorySlug != "clear"){
    // Set the selected category and trigger the search
    // Filter the original list of businesses
    let filtered = originalBusinesses.filter((business: any) => {
      // Parse the business_directory[0] string to an object
      let directoryObject = business.business_directory
      console.log(directoryObject)
      console.log("Hello there")
      if(directoryObject.length > 0){
        // Access business_categories property and filter based on selectedCategory
        let hasCategory = directoryObject[0].business_categories.some(
          (category: any) => 
            category.category_name.toLowerCase() === categorySlug
        );
        return hasCategory;
      }
    });
      setSelectedCategory(categorySlug)
      setFilteredBusinesses(filtered)
    //handleSearch();
    } else {
      setSelectedCategory('')
      setFilteredBusinesses(originalBusinesses)
    }
  };

  return (
    <div className="Directory-page">
      {/* SEO Metadata */}
      <Helmet>
        <title>Business Directory - Business Bloc</title>
        <meta name="description" content="Explore our business directory to discover companies and services powered by Business Bloc's AI-driven solutions." />
        <meta name="keywords" content="business directory, company listings, local businesses, Business Bloc, AI business tools, digital presence, services directory" />
        <meta property="og:title" content="Business Directory - Business Bloc" />
        <meta property="og:description" content="Find businesses and services within Business Bloc's AI-powered network, helping companies grow and thrive online." />
        <meta property="og:image" content={heroImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="Page-Header Business-Directory" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${businessDirectoryMain}), url(${businessDirectoryMain})` }}>
        <div className="Page-Header-Main-Section-Full-Width">
        <div className="Page-Header-content-full-width">

          <div className="Page-Header-content-100">
              <h1>Business Directory</h1>
              <h2>Start searching for a local business</h2>
              <div className="directory-business-search-container">
                <input  
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Type a business name" 
                  type="text"
                />
                <button onClick={handleSearch}>Search</button>
              </div>
          </div>
          </div>
        </div>
      </section>
      <section className="Page">
        <div className="Page-content">
          <div className="business-list-items">
            {currentBusinesses.map((business: any, index: any) => (
              <BusinessItem key={index} {...business} />
            ))}
            {/* Pagination */}
            <ul className="pagination">
              {Array.from({ length: Math.ceil(filteredBusinesses.length / businessesPerPage) }, (_, index) => (
                <li
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? 'active' : ''}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          </div>
          <div className="business-list-sidebar">
            <div className="business-list-categories">
              <h2>Filter</h2>
              <div className="business-list-categories-inner">
                <ul>
                  { selectedCategory != '' ?
                  <li onClick={() => handleCategoryClick("clear")} className="business-category-clear-button"><i className="fa fa-close"></i> Clear</li>
                  : "" }                  
                  {Array.isArray(uniqueCategories) && uniqueCategories.length > 0 ? (
                    uniqueCategories.map((category: any, index: any) => (
                      <li
                        key={index}
                        className={category.slug}
                        onClick={() => handleCategoryClick(category.slug)}
                      >
                        <a>{category.name}</a>
                      </li>
                    ))
                  ) : (
                    <>
                      <i className="fa fa-search"></i> Please select a location to filter further
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="business-list-locations">
              <h2>Locations</h2>
                <div className="business-list-locations-inner">
                  <ul>
                    {businessLocations.map((state: any, index: any) => (
                      <li key={index} className={state.slug}>
                        <a href={"/directory/canada/" + state.slug}>{state.state}</a>
                        <ul>
                          {state.cities.map((city: any, cityIndex: any) => (
                            <li key={cityIndex} className={city.slug}>
                              <a href={"/directory/canada/" + state.slug + "/" + city.slug}>{city.name}</a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Referral;
