import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { writeFileSync } from 'fs';
import path from 'path';

// Front-facing
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import EmailUnsubscribe from './components/EmailUnsubscribe/EmailUnsubscribe';
import About from './components/About/About';
import Services from './components/Services/Services';
import GetStarted from './components/GetStarted/GetStarted';
import Tools from './components/Tools/Tools';
import ToolDetail from './components/Tools/ToolDetail';
import Checkout from './components/Checkout/Checkout';
import Login from './components/Login/Login';
import Directory from './components/Directory/Directory';
import CityList from './components/Directory/CityList';
import StateList from './components/Directory/StateList';
import CountryList from './components/Directory/CountryList';
import BusinessDetail from './components/Directory/BusinessDetail';


// Speciality 
import Speciality_WebsiteGeneration from './components/Specialty/WebsiteGeneration/Home';
import axios from 'axios';
import { BusinessAuthContext } from './helpers/AuthContext';



import Footer from './components/Footer/Footer';
import './App.css';
import Referral from './components/Referral/Referral';

const App: React.FC = () => {
  const [appLoading, setAppLoading] = useState(true)
  const [blocModules, setBlocModules] = useState([])
  const [auth, setAuth] = useState<BusinessAuthContext>({
    entity: {
      type: "business",
      id: 0
    },
    name: "",
    email: "",
    rank: "",
    status: false,
    bloc_services: []
  });

  /* FEATURE: Auto generates a site map based on the routes listed below that we want Google to rank */

  const generateSitemap = () => {
    const baseUrl = 'https://businessbloc.com'; // Replace with your domain
    const routes = [
      { path: '/', priority: 1.0, changeFreq: 'daily' },
      { path: '/about', priority: 0.8, changeFreq: 'weekly' },
      { path: '/pricing', priority: 0.8, changeFreq: 'weekly' },
      { path: '/business-tools', priority: 0.6, changeFreq: 'weekly' },
      { path: '/business-tools/website-maintenance', priority: 0.6, changeFreq: 'weekly' },
      { path: '/referral-program', priority: 0.6, changeFreq: 'weekly' },
      { path: '/directory', priority: 0.6, changeFreq: 'daily' },
      { path: '/directory/canada/alberta/barrhead', priority: 0.6, changeFreq: 'daily' },
    ];
    const sitemap = `
      <?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${routes
          .map(
            (route) => `
            <url>
              <loc>${baseUrl}${route.path}</loc>
              <changefreq>${route.changeFreq}</changefreq>
              <priority>${route.priority}</priority>
            </url>
          `
          )
          .join('')}
      </urlset>
    `;

    // Dynamically create a Blob to save the sitemap
    const blob = new Blob([sitemap.trim()], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);

    // Automatically download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sitemap.xml';
    document.body.appendChild(a); // Append the element to the DOM
    a.click();
    document.body.removeChild(a); // Remove the element after clicking
    URL.revokeObjectURL(url); // Clean up the Blob URL
  };

  /* FEATURE: Retrieve cookies to ensure logging in and logging out works correctly */

  const getCookie = (key: string): string | null => {
    const match = document.cookie.match(new RegExp("(^|;)\\s*" + key + "=\\s*([^;]+)"));
    return match ? decodeURIComponent(match.pop() || '') : "";
  };
  
  useEffect(() => {
    const genBusinessBlocAuth = async () => {
      //let businessBlocAuthToken = localStorage.getItem("business_bloc_token")
      const accessToken = await getCookie("accessToken");

      // INFO! Need to generate a new sitemap, uncheck the following function below
      // Please keep in mind, it will spam a download of the sitemap, need to fix it so it only downloads once in future (Just as an FYI)
      //generateSitemap(); // Generate sitemap on app load

      //alert("ja ja")
      //alert(accessToken)
      if(accessToken != null){
        await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + "/business/auth", {
         withCredentials: true,
        }).then((response: any) => {  
          //alert(JSON.stringify(response))
          //alert(response)
          //alert("Access token is logged in") 
          if(auth.status == false){
            setAuth({
              entity: {
                type: "business",
                id: response.data.entity.id
              },
              name: response.data.name,
              email: response.data.email,
              rank: response.data.rank,
              bloc_services: response.data.bloc_services,
              status: true,
            });
          }
          setBlocModules(response.data.bloc_services)
        }).catch((e) => {
          //alert(e)
            setAuth({
              entity: {
                type: "business",
                id: 0
              },
              name: "",
              email: "",
              rank: "",
              bloc_services: [],
              status: false,
            });
        });
      } else {
        //alert("Logged out")
      }
    };
    genBusinessBlocAuth().then((authResponse) => {
      setAppLoading(false)
    })
  }, [auth, appLoading])
  return (        
  <HelmetProvider>
    <BrowserRouter>
        {auth ?
        <div className="App">
          <Helmet>
            <title>Business Bloc - One dashboard for your business</title>
            <meta name="description" content="Business Bloc provides a range of digital solutions including website design, website hosting and tools for local and enterprise businesses, including an AI website builder and AI logo generator. We also offer services such as pay per click management, AI prompt engineering and software development." />
            <meta name="keywords" content="Business Bloc, website maintenance, wordpress maintenance, ecommerce maintenance, AI website builder, AI logo generator, pay per click management, prompt engineering, software development, local business, enterprise business" />
          </Helmet>
          <Header businessAuth={auth}/>
          <Routes>
            <Route 
              path="/" 
              element={<Home businessAuth={auth} appLoading={appLoading} />}
            />
            <Route 
              path="/create-free-website" 
              element={<Speciality_WebsiteGeneration businessAuth={auth} appLoading={appLoading} />}
            />
            <Route 
              path="/create-business-website" 
              element={<Speciality_WebsiteGeneration businessAuth={auth} appLoading={appLoading} />}
            />
            <Route path="/email/unsubscribe/:id" Component={EmailUnsubscribe}/>
            <Route path="/about" Component={About}/>
            <Route path="/business-tools" Component={Tools}/>
            <Route path="/business-tools/:tool_unique_id" Component={ToolDetail} />
            <Route path="/referral-program" Component={Referral}/>
            <Route 
              path="/pricing" 
              element={<Services businessAuth={auth} />}
            />

            { /* Directory */ }
            <Route path="/directory" Component={Directory}/>
            <Route path="/directory/:country" Component={CountryList} />
            <Route path="/directory/:country/:state" Component={StateList} />
            <Route path="/directory/:country/:state/:city" Component={CityList} />
            <Route path="/directory/:country/:state/:city/:business_unique_id" Component={BusinessDetail} />
            { }
            <Route 
              path="/get-started" 
              element={<GetStarted businessAuth={auth} appLoading={appLoading} />}
            />
            <Route 
              path="/login" 
              element={<Login businessAuth={auth} appLoading={appLoading} />}
            />
            <Route 
              path="/checkout" 
              element={<Checkout businessAuth={auth} appLoading={appLoading} />}
            />
          </Routes>
          <Footer />
        </div>
        : "" }
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
