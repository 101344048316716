import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import PageHeader from '../PageHeader/PageHeader'
import { Helmet } from 'react-helmet-async';
import './GetStarted.css'
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import * as Yup from "yup";
import heroImage from '../../assets/images/about/business-bloc-about-us.png';

type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
}

const GetStarted: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralBusinessId = queryParams.get('referral');

  const initialValues: FormValues = {
    business_name: '',
    business_email: '',
    business_password: ''
  }
  let currentAuthBusiness = businessAuth.status
  useEffect(() => {
    if(currentAuthBusiness == true){
      window.location.href = '/'
    }
    window.scrollTo({
      top: 0
    })
  }, []);
  const validationSchema = Yup.object().shape({
    business_name: 
      Yup.string()
      .min(2, "Your business name must be at least 2 characters.")
      .max(50, "Your businss name must be a maximum of 50 characters.")
      .matches(/^[\w ]*[^\W_][\w ]*$/, "Your business name may only contain letters and numbers.")
      .required("You must provide a business name that is between 2-50 characters."),
    business_email: 
      Yup.string()
      .email("You must provide an email address such as \"example@gmail.com\"")
      .min(5, "Your email address must be a minimum of 5 characters.")
      .max(60, "Your email address must be a maximum of 60 characters.")
      .required("You must provide an email address that is between 5-60 characters."),
    business_password: Yup.string()
      .min(8, "Your password must be a minimum of 8 characters.")
      .max(35, "Your password must be a maximum of 35 characters.")
      .matches(/[a-zA-Z]/, 'Your password must contain supported language letters.')
      .matches(/[@$!%*#?&]+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .matches(/\d+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .required("Your password may only be between 8-35 characters and contain at least one number and special character."),
  });
  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    setDisabled(true)
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/create', { 
      values 
    }, { withCredentials: true }).then((successAccountCreated) => {
      if(successAccountCreated.status == 200 && successAccountCreated.data.errorMessage == undefined){
        alert(successAccountCreated)
        localStorage.setItem("business_bloc_token", successAccountCreated.data.token)   
        if(referralBusinessId != null){
          localStorage.setItem("referral_id", referralBusinessId)
        }
        console.log("JAJAJDASN")
        console.log(successAccountCreated)
        window.location.href = "/checkout";
      } else {
        setDisabled(false)
        setErrorMessage(successAccountCreated.data.errorMessage )
      }
    }).catch((e) => { 
      alert(e)
      setDisabled(false)
      setErrorMessage("Unable to connect to server. Please retry in five minutes.")
    })
  }   
  return (
    <div className="Get-Started-Page Spotlight">
      {/* SEO Metadata */}
      <Helmet>
        <title>Get Started - Business Bloc</title>
        <meta name="description" content="Get started with Business Bloc and begin transforming your business with AI-powered tools and services." />
        <meta name="keywords" content="get started, Business Bloc, AI business tools, digital transformation, AI solutions, business onboarding, business growth" />
        <meta property="og:title" content="Get Started - Business Bloc" />
        <meta property="og:description" content="Join Business Bloc today and unlock AI-driven solutions to grow your business online." />
        <meta property="og:image" content={heroImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="Spotlight-Block" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.7), rgba(98, 0, 134, 0.68)), url(${heroImage}), url(${heroImage})` }}>

      </section>
      <section className="Get-Started-Header">
        <div className="Get-Started-Header-Main-Section">
          <div className="Get-Started-Header-content">
              <ul className="Get-Started-Step-List">
                <li className="Get-Started-Step-Indicator">Step 1 of</li>
                <li className="Get-Started-Step-Final-Step">Step 2</li>
              </ul>
              { /* STEP #1 */ }
              <div className="step">
              <h2>Register your business in less than 30 seconds</h2>
                {/*<div className="Get-Started-Input-Container">
                  <select className="Get-Started-Prefix-Select">
                    <option>http://</option>
                    <option>https://</option>
                    <option>http://www.</option>
                    <option>https://www.</option>
                  </select>
                  <input className="Get-Started-Input" placeholder="Website URL" />
                </div>*/}
                <Formik
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                >
                  <Form className="Get-Started-Account-Details-Container">
                      { errorMessage != "" ?
                      <div className="Hero-Register-Business-Error-Message">{errorMessage}</div>
                      : "" }
                      <div className="Get-Started-Account-Detail">
                        <ErrorMessage className="Error-Message" name="business_name" component="span" />
                        <Field type="text" placeholder="Business name" name="business_name" />
                      </div>
                      <div className="Get-Started-Account-Detail">
                        <ErrorMessage className="Error-Message" name="business_email" component="span" />
                        <Field type="text" placeholder="Email" name="business_email"/>
                      </div>
                      <div className="Get-Started-Account-Detail">
                        <ErrorMessage className="Error-Message" name="business_password" component="span" />          
                        <Field type="password" placeholder="Password" name="business_password"/>
                      </div>
                      <div className="Get-Started-Account-Detail Next-Step">
                        <button disabled={disabled}>Next step <i className="fa-solid fa-arrow-right"></i></button>
                      </div>
                  </Form>
                </Formik>
              </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GetStarted;
