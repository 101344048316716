import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';


interface BusinessListingFormValues {
  businessDisplayName: string;
  businessDescription: string;
  businessPhoneNumber: string;
  businessAddress: string;
  socialMediaLinks: string;
  hoursOfOperation: string;
  businessImages: FileList | null;
  subscribeToEmails: boolean;
}

const initialValues: BusinessListingFormValues = {
  businessDisplayName: '',
  businessDescription: '',
  businessPhoneNumber: '',
  businessAddress: '',
  socialMediaLinks: '',
  hoursOfOperation: '',
  businessImages: null,
  subscribeToEmails: false,
};

const BusinessListingForm: React.FC = () => {
  const handleSubmit = (values: BusinessListingFormValues) => {
    // Handle form submission, e.g., send data to server
    console.log('Submitted values:', values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <div>
          <label htmlFor="businessDisplayName">Business Display Name:</label>
          <Field type="text" id="businessDisplayName" name="businessDisplayName" />
          <ErrorMessage name="businessDisplayName" component="div" />
        </div>

        <div>
          <label htmlFor="businessDescription">Business Description:</label>
          <Field as="textarea" id="businessDescription" name="businessDescription" />
          <ErrorMessage name="businessDescription" component="div" />
        </div>

        <div>
          <label htmlFor="businessPhoneNumber">Business Phone Number:</label>
          <Field type="tel" id="businessPhoneNumber" name="businessPhoneNumber" />
          <ErrorMessage name="businessPhoneNumber" component="div" />
        </div>

        <div>
          <label htmlFor="businessAddress">Business Address:</label>
          <Field type="text" id="businessAddress" name="businessAddress" />
          <ErrorMessage name="businessAddress" component="div" />
        </div>

        <div>
          <label htmlFor="socialMediaLinks">Social Media Links:</label>
          <Field type="text" id="socialMediaLinks" name="socialMediaLinks" />
          <ErrorMessage name="socialMediaLinks" component="div" />
        </div>

        <div>
          <label htmlFor="hoursOfOperation">Hours of Operation:</label>
          <Field type="text" id="hoursOfOperation" name="hoursOfOperation" />
          <ErrorMessage name="hoursOfOperation" component="div" />
        </div>

        <div>
          <label htmlFor="businessImages">Business Images:</label>
          <Field type="file" id="businessImages" name="businessImages" multiple />
          <ErrorMessage name="businessImages" component="div" />
        </div>
        
        <div>  
          <label>
            <Field type="checkbox" name="subscribeToEmails" />
            &nbsp;I want to receive emails about my listing, including new messages and promotional messages from Business Bloc.
          </label>
          <ErrorMessage name="subscribeToEmails" component="div" />
        </div>

        <button type="submit">Submit Listing</button>
      </Form>
    </Formik>
  );
};

export default BusinessListingForm;
