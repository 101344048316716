import React from 'react';
import Hero from './Hero';
import LoggedInHero from './LoggedInHero';

import Pricing from '../Pricing/Pricing';
import HowItWorks from '../HowItWorks/HowItWorks';
import './Home.css';

const Home: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  return (
    <>
    { 
    //businessAuth.status == false ?
    <div className="Home">
      <Hero businessAuth={businessAuth} appLoading={appLoading} />
      <HowItWorks />
      <Pricing businessAuth={businessAuth} />
    </div>
    /*: 
    <div className="Home-logged-in">
      <LoggedInHero businessAuth={businessAuth} />
    </div>*/
    }
    </>
  );
};

export default Home;
