// BusinessItem.tsx
import React from 'react';
import './ToolItem.css'; // Import the CSS file

interface BusinessAddress {
  country?: any;
  state?: any;
  city?: any;
  postal_code?: any;
  street_address?: any;
  // Add more properties as needed
}

interface BusinessItemProps {
  name: string;
  tool_unique_id: string;
  part_of_plan: string;
  tool_categories: string;
  description?: any;
  subtitle: string;
  tool_images: any;
}

const ToolItem: React.FC<BusinessItemProps> = ({ name, tool_unique_id, part_of_plan, tool_categories, description, subtitle, tool_images }) => {
  

// Parse the stringified JSON into a JavaScript object
/*const parsedDirectoryDetails = JSON.parse(business_directory)*/
const businessDisplayName = name
//const businessUniqueId = parsedDirectoryDetails[0].business_unique_id
let toolImages = null
if(tool_images != null){
  toolImages = tool_images
} 

let partOfPlan = part_of_plan
let message
let planName
let price
let iconClass = "";

if (partOfPlan.includes("free")) {
  message = "Available for free";
  iconClass = "fa fa-check"; // Check icon for free
  planName = 'free';
  price = 'Free'
} else if (partOfPlan.includes("web-hosting") && partOfPlan.includes("complete")) {
  message = "Available to all plan members";
  iconClass = "fa fa-shield"; // Check icon for free
  planName = 'all-plans';
  price = '$15/monthly';
} else if (partOfPlan.includes("web-hosting")) {
  message = "Web hosting required";
  iconClass = "fa fa-server"; // Check icon for free
  planName = 'web-hosting';
  price = '$15/monthly';
} else if (partOfPlan.includes("complete")) {
  message = "Complete plan required";
  iconClass = "fa fa-shield"; // Check icon for free
  planName = 'complete';
  price = '$45/monthly';
} else {
  message = "No specific plan details available";
}

  return (
    <div className="business-item tool-item">
      <div className="business-item-inner">
        <div className="tool-item-inner-image">
          {/* image != undefined ?
            <img src={image} alt={businessDisplayName} />
          : */}
          { 
            toolImages != null ?
            <img src={process.env.REACT_APP_BLOC_SERVER_URL + toolImages[0].url}/>
            : 
            <i className="fa fa-gear"></i> 
          }
        </div>
        <div className="tool-item-inner-details-container">
          <div className="tool-item-inner-details">
            <h2>{businessDisplayName}</h2>        
            <p className={`plan-detail ${planName}`}><i className={iconClass}></i> {message} <span className="price">{price}</span></p>            
          </div>
          <p className="tool-subtitle">{subtitle}</p>
            <a href={`/business-tools/${tool_unique_id}`}>Learn more <i className="fa fa-chevron-right"></i></a>
          </div>
          <div className="mobile-plan-details">
            <p className="tool-subtitle">{subtitle}</p>
            <a href={`/business-tools/${tool_unique_id}`}>Learn more <i className="fa fa-chevron-right"></i></a>
          </div>
      </div>
    </div>
  );
};

export default ToolItem;
