// BusinessItem.tsx
import React from 'react';
import './BusinessItem.css'; // Import the CSS file

interface BusinessAddress {
  country?: any;
  state?: any;
  city?: any;
  postal_code?: any;
  street_address?: any;
  // Add more properties as needed
}

interface BusinessItemProps {
  business_name: string;
  business_directory: any;
  image: string;
  business_address?: any;
  business_phone_number: string;
  country: string,
  state: string,
  city: string,
  business_claimed_id: string
}

const BusinessItem: React.FC<BusinessItemProps> = ({ business_name, business_directory, image, business_address, business_phone_number, country, state, city, business_claimed_id }) => {
if(business_directory && business_directory.length > 0){
  const parsedDirectoryDetails = business_directory 
if(parsedDirectoryDetails != null){

// Parse the stringified JSON into a JavaScript object
const parsedAddress = business_address;
const businessDisplayName = parsedDirectoryDetails[0]?.business_name
const businessUniqueId = parsedDirectoryDetails[0]?.business_unique_id
const businessImages = parsedDirectoryDetails[0]?.business_images[0]
// Access the 'country' property
const businessCountry = parsedAddress.find((item: BusinessAddress) => item.country)?.country;
const businessState = parsedAddress.find((item: BusinessAddress) => item.state)?.state;
const businessCity = parsedAddress.find((item: BusinessAddress) => item.city)?.city;
const businessStreetAddress = parsedAddress.find((item: BusinessAddress) => item.street_address)?.street_address;


// Transform to lowercase and replace spaces with hyphens
const formattedBusinessCountry = businessCountry?.toLowerCase().replace(/\s/g, '-');
const formattedBusinessState = businessState?.toLowerCase().replace(/\s/g, '-');
const formattedBusinessCity = businessCity?.toLowerCase().replace(/\s/g, '-');


  return (
    <div className="business-item ">
      <div className="business-item-inner">
        <div className="business-item-inner-image">
          { image != undefined ?
            <img src={image} alt={businessDisplayName} />
          : 
            <i className="fa fa-building"></i> }
        </div>
        <div className="business-item-inner-details">
          <h2>{businessDisplayName}</h2>          
          <p><i className="fa fa-folder"></i> {parsedDirectoryDetails?.[0]?.business_categories?.[0]?.category_name?.replaceAll('-', ' ') || 'N/A'}</p>
          <p><i className="fa fa-map-marker"></i>{businessStreetAddress}</p>
          <p><i className="fa fa-phone"></i> {business_phone_number}</p>
          <a href={`/directory/${formattedBusinessCountry}/${formattedBusinessState}/${formattedBusinessCity}/${businessUniqueId}`}>Learn more <i className="fa fa-chevron-right"></i></a>
        </div>
        {/* Add other details as needed */}
      </div>
    </div>
  );

          } else {
            return (
              <>
                <p className="no-listings-available"><i className="fa fa-wifi"></i> It appears no listings are currently available. </p>
              </>
            )
          }
} else {
  return (
    <>
    </>
  )
}
};

export default BusinessItem;
