import React from 'react';
import Hero from './Hero';
import LoggedInHero from './LoggedInHero';

import Pricing from '../../Pricing/Pricing';
import WebsiteFeatures from './WebsiteFeatures';
import './Home.css';

const Speciality_WebsiteGeneration: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  return (
    <>
    { 
    //businessAuth.status == false ?
    <div className="Create-Business-Website">
      <Hero businessAuth={businessAuth} appLoading={appLoading} />
      <WebsiteFeatures businessAuth={businessAuth} appLoading={appLoading} />
    </div>
    /*: 
    <div className="Home-logged-in">
      <LoggedInHero businessAuth={businessAuth} />
    </div>*/
    }
    </>
  );
};

export default Speciality_WebsiteGeneration;
