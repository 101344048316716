import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../PageHeader/PageHeader';
import './EmailUnsubscribe.css';
import heroImage from '../../assets/images/about/welcome-to-business-bloc.webp';

const EmailUnsubscribe: React.FC = () => {
  return (
    <div className="About-page">
      {/* SEO Metadata */}
      <Helmet>
        <title>Email unsubscribe - Business Bloc</title>
        <meta name="description" content="Learn about Business Bloc and how we help businesses enhance their digital presence." />
        <meta property="og:title" content="About Business Bloc" />
        <meta property="og:description" content="Helping businesses elevate their digital assets with AI-powered solutions." />
        <meta property="og:image" content={heroImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* Page Content */}
      <PageHeader 
        pageTitle="We're sad to see you go" 
        pageImage={heroImage} 
        pageDescription="You has been successfully been removed from receiving future emails." 
        template=""
      />

      <section className="Page">
        <div className="Page-content">
          <div className="Page-content-inner">
            <div className="About-info">
              <p>You have been successfully unsubscribed from future emails. If this was an error, you may re-subscribe at any time.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmailUnsubscribe;
