import React, { useState } from 'react';
import PageHeader from '../PageHeader/PageHeader'
import { Helmet } from 'react-helmet-async';
import './Tools.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import businessDirectoryMain from '../../assets/images/tools/tools.png';
import ToolItem from './ToolItem';


interface Params {
  entity: string
}


type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
  business_website_url: string
}

interface ToolCategory {
  name: string;
  iconClass: string;
}

const Tools: React.FC = () => {

  const businessesPerPage = 12;

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [directoryItemData, setDirecoryItemData] = useState<any>([]);
  
  const [uniqueCategories, setUniqueCategories] = useState<any>([]);

  

  const [loadPageAssets, setLoadPageAssets] = useState(false);

  
  const params: Params = {
    entity: ""
  }

  let businessBlocAuthToken = localStorage.getItem("business_bloc_token")

  const [filteredTools, setFilteredTools] = useState<any>([]);
  const [originalTools, SetOriginalTools] = useState<any>([]); // Original list of businesses
  const [selectedCategory, setSelectedCategory] = useState('');

  async function findSupportTickets() {
    await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + '/tool/retrieve-all', {
      params, 
    }).then((retrieveSupportTickets: any) => {
      if(retrieveSupportTickets.data.length != 0){
        SetOriginalTools(retrieveSupportTickets.data)
        setFilteredTools(retrieveSupportTickets.data)
        // Categories
        const originalBusinesses = retrieveSupportTickets.data;

        // Create a set to store unique categories
        const uniqueCategoriesSet = new Set();
      
        // Function to convert category_name to the desired format
        const formatCategoryName = (categoryName: any) => {
          return {
            name: categoryName.replace(/[_-]/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase()),
            slug: categoryName.replace(/_/g, '-').toLowerCase()
          };
        };
        
        // Iterate over each business
        originalBusinesses.forEach((business: any) => {
          // Check if the business has the 'business_categories' property
          if (business.business_directory && business.business_directory.length > 0) {
            const businessDirectory = JSON.parse(business.business_directory)

            //const businessCategoriesItem = JSON.parse(businessDirectory.business_categories)
            //console.log(businessCategoriesItem)

            const businessCategories = businessDirectory[0].business_categories;
            //console.log(businessCategories)
      
            // Check if business_categories is an array
            if (Array.isArray(businessCategories)) {
              // Iterate over each category in the business
              businessCategories.forEach((category) => {
                // Add formatted category to the set
                if (category.category_name) {
                  uniqueCategoriesSet.add(formatCategoryName(category.category_name));
                }
              });
            }
          }
        });
      
        // Convert the set to an array
        const uniqueCategories = Array.from(uniqueCategoriesSet);
      
        // Log or do something with the unique categories array
        setUniqueCategories(uniqueCategories)

      }
    })
  }

  React.useEffect(() => {
    findSupportTickets().then(() => {
      setLoadPageAssets(true)
    })
  }, [])

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 10);
  };

  const handleSearch = () => {
    const term = searchTerm.toLowerCase().trim();

    // Filter the original list of businesses
    let filtered = originalTools.filter((business: any) =>
      business.name.toLowerCase().includes(term)
    );

    if (selectedCategory) {
      filtered = filtered.filter((business: any) =>
        business.tool_categories.some(
          (category: any) => category.category_name.toLowerCase() === selectedCategory
        )
      );
    }

    setFilteredTools(filtered);
    setSelectedCategory('');
    setCurrentPage(1);
    scrollToTop();
  };

  const indexOfLastBusiness = currentPage * businessesPerPage;
  const indexOfFirstBusiness = indexOfLastBusiness - businessesPerPage;
  const currentBusinesses = filteredTools.slice(
    indexOfFirstBusiness,
    indexOfLastBusiness
  );

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    scrollToTop(); // Scroll to the top when changing the page
  };
  
  const navigate = useNavigate();

  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/create', { values }).then((successAccountCreated) => {
      localStorage.setItem("business_bloc_token", successAccountCreated.data.token)   
      navigate("/checkout");
    })
  }
  
  const businessLocations = [{
    "state": "Alberta",
    "slug": "alberta",
    "cities": [
      {
        "name": "Barrhead",
        "slug": "barrhead"
      }
    ]
  }]

  const handleCategoryClick = (categorySlug: string) => {
    if(categorySlug != "clear"){
    // Set the selected category and trigger the search
    // Filter the original list of businesses
    let filtered = originalTools.filter((business: any) => {
      // Parse the business_directory[0] string to an object
      let directoryObject = business.tool_categories
      // Access business_categories property and filter based on selectedCategory
      if (directoryObject && Array.isArray(directoryObject)) {
        let hasCategory = directoryObject.some(
          (category: any) => 
            category.category_name.toLowerCase() === categorySlug
        );
        return hasCategory;
      } else {
        return;
      }
    });
      setSelectedCategory(categorySlug)
      setFilteredTools(filtered)
    //handleSearch();
    } else {
      setSelectedCategory('')
      setFilteredTools(originalTools)
    }
  };


  const toolCategories: ToolCategory[] = [
    { name: "Everyone", iconClass: "fa-building" },
    { name: "Construction", iconClass: "fa-wrench" },
    { name: "Tech Startups", iconClass: "fa-laptop" },
    { name: "Fitness", iconClass: "fa-dumbbell" },
    { name: "Food & Beverage", iconClass: "fa-coffee" },
    { name: "Healthcare", iconClass: "fa-medkit" },
    { name: "Real Estate", iconClass: "fa-building" },
    { name: "Finance", iconClass: "fa-dollar-sign" },
    { name: "E-commerce", iconClass: "fa-shopping-cart" },
    { name: "Education", iconClass: "fa-graduation-cap" },
    { name: "Travel", iconClass: "fa-plane" },
    { name: "Art & Design", iconClass: "fa-paint-brush" },
    { name: "Automotive", iconClass: "fa-car" },
    { name: "Entertainment", iconClass: "fa-film" },
    { name: "Hospitality", iconClass: "fa-hotel" },
    { name: "Churches", iconClass: "fa-cross" },
  ];

  return (
    <>
    <div className="Directory-page Tool-directory-page">
      <Helmet>
        <title>Business Tools - Business Bloc</title>
        <meta name="description" content="Discover a variety of free and premium business tools designed to streamline and enhance your business operations." />
        <meta name="keywords" content="business tools, free business tools, paid business tools, operations tools, business operations, productivity tools, Business Bloc, business software" />
        <meta property="og:title" content="Business Tools - Business Bloc" />
        <meta property="og:description" content="Explore Business Bloc’s collection of free and paid tools to help improve and optimize your business operations." />
        <meta property="og:image" content={businessDirectoryMain} />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="Page-Header Business-Directory" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${businessDirectoryMain}), url(${businessDirectoryMain})` }}>
        <div className="Page-Header-Main-Section-Full-Width">
        <div className="Page-Header-content-full-width">

          <div className="Page-Header-content-100">
              <h1>Business Tools</h1>
              <h2>Start searching for online tools for your business</h2>
              <div className="directory-business-search-container">
                <input  
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search for a business tool" 
                  type="text"
                />
                <button onClick={handleSearch}>Search</button>
              </div>
          </div>
          </div>
        </div>
      </section>
      <section className="Page">
        <div className="Page-content">
          <div className="business-list-items">
            {currentBusinesses.map((business: any, index: any) => (
              <ToolItem key={index} {...business} />
            ))}
            {/* Pagination */}
            {Math.ceil(filteredTools.length / businessesPerPage) > 1 && (
              <ul className="pagination">
                {Array.from({ length: Math.ceil(filteredTools.length / businessesPerPage) }, (_, index) => (
                  <li
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                  >
                    {index + 1}
                  </li>
                ))}
              </ul>
            )}

          </div>

          <div className="Tool-filter-sidebar">
          <h2>Filter</h2>
          <div className="Page-content-inner">
          <div className="Tool-filter">
          <ul>
            {toolCategories.map((category: any) => (
              <li key={category.name}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCategoryClick(category.name.toLowerCase());
                  }}
                >
                  <i className={`fa ${category.iconClass}`}></i>
                  {category.name}
                </a>
              </li>
            ))}
          </ul>
          </div>
          </div>
        </div>

        </div>
      </section>
    </div>
  </>
    
  );
};

export default Tools;

interface BusinessToolProps {
  category: string;
  iconClass: string;
}




const BusinessTool: React.FC<any> = ({ category, iconClass, handleCategoryClick  }) => {

  
  const handleFilter = (event: React.MouseEvent) => {
    event.preventDefault();
    console.log('handleCategoryClick:', handleCategoryClick);
    handleCategoryClick(category.toLowerCase());
  };

  return (
    <li>
      <a href="#" onClick={handleFilter}>
        <i className={`fa ${iconClass}`}></i>
        {category}
      </a>
    </li>
  );
};