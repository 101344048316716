import React, { useState } from 'react';
import './WebsiteFeatures.css';

import cafeHighlightImage from  '../../../assets/images/create-business-website/cafe/business-highlight-cafe-image-1.png';
import generalFeaturesImage from  '../../../assets/images/create-business-website/general-features/business-highlight-general-features-1.png';
import constructionHighlightImage from  '../../../assets/images/create-business-website/construction/business-highlight-construction-image-1.png';

const features: any = {
  general: [
    { icon: 'fa-magic', heading: 'Automatically Generated Custom Website', description: 'Launch your personalized website in under a minute, tailored to your specific business needs.' },
    { icon: 'fab fa-wordpress', heading: 'WordPress Hosted', description: 'Enjoy the reliability and flexibility of WordPress hosting, ensuring your site is always accessible and easy to manage.' },
    { icon: 'fa-search', heading: 'SEO Optimization', description: 'Rank higher on Google search results with built-in SEO features designed to boost your online visibility.' },
    { icon: 'fa-calendar', heading: 'Events Calendar', description: 'Manage and promote events effortlessly. Allow customers to RSVP, subscribe to newsletters, and engage with your community.' },
    { icon: 'fa-server', heading: 'Web Hosting Included', description: 'Enjoy hassle-free web hosting with our comprehensive package, ensuring your site is always up and running.' },
    { icon: 'fa-shopping-cart', heading: 'Ecommerce', description: 'Enable customers to place orders directly from your website, with seamless integration for delivery or pickup options.' },
    { icon: 'fa-envelope', heading: 'Contact Forms', description: 'Easily capture customer inquiries and feedback with customizable contact forms.' },
    { icon: 'fa-share-alt', heading: 'Social Media Integration', description: 'Connect your social media accounts to your website, allowing visitors to engage with your content across platforms.' },
    { icon: 'fa-pencil-alt', heading: 'Blog Functionality', description: 'Share news, updates, and articles with a built-in blogging platform to keep your audience informed and engaged.' },
    { icon: 'fa-images', heading: 'Photo Gallery', description: 'Display beautiful images of your business, products, or events with an easy-to-use photo gallery feature.' },
    { icon: 'fa-comments', heading: 'Testimonials', description: 'Showcase customer reviews and testimonials to build trust and credibility with potential clients.' },
    { icon: 'fa-paint-brush', heading: 'Customizable Themes', description: 'Choose from a variety of themes and templates to match your brand’s unique style and aesthetic.' },
    { icon: 'fa-chart-line', heading: 'Analytics Dashboard', description: 'Track your website’s performance with detailed analytics, helping you make informed decisions to grow your business.' },
    { icon: 'fa-lock', heading: 'Secure Payment Processing', description: 'Accept payments securely through your website with integrated payment gateways.' },
  ],
  construction: [
    { icon: 'fa-hammer', heading: 'Service Listings', description: 'Showcase all your construction services—from remodeling to new builds—with detailed descriptions and imagery.' },
    { icon: 'fa-images', heading: 'Project Portfolio', description: 'Display a stunning gallery of completed projects that highlights your craftsmanship and expertise.' },
    { icon: 'fa-trophy', heading: 'Awards & Certifications', description: 'Highlight your industry accolades and certifications to build trust and credibility with potential clients.' },
  ],
  cafe: [
    { icon: 'fa-cutlery', heading: 'Menu Management', description: 'Showcase your restaurant’s menu online with detailed descriptions, pricing, and special dietary options.' },
    { icon: 'fa-calendar-check', heading: 'Reservation System', description: 'Allow customers to book tables online with an easy-to-use reservation system.' },
    { icon: 'fa-star', heading: 'Daily Specials', description: 'Highlight your daily specials and promotions to attract more customers.' },
    { icon: 'fa-calendar', heading: 'Events Calendar', description: 'Manage and promote events effortlessly. Allow customers to RSVP, subscribe to newsletters, and engage with your community.' },
    { icon: 'fa-users', heading: 'Meet the Team', description: 'Showcase your team members with bios and photos to create a personal connection with your customers.' },
  ],
  church: [
    { icon: 'fa-book', heading: 'Sermon Library', description: 'Upload and share sermon recordings, notes, and videos with your congregation.' },
    { icon: 'fa-hands-helping', heading: 'Volunteer Sign-Up', description: 'Organize and manage volunteer opportunities with a simple sign-up system.' },
    { icon: 'fa-donate', heading: 'Donation Integration', description: 'Facilitate online donations with integrated payment solutions to support your church’s mission.' },
    { icon: 'fa-calendar', heading: 'Events Calendar', description: 'Manage and promote events effortlessly. Allow customers to RSVP, subscribe to newsletters, and engage with your community.' },
    { icon: 'fa-users', heading: 'Meet the Team', description: 'Showcase your team members with bios and photos to create a personal connection with your congregation.' },
  ],
};

const SpecialityWebsiteFeatures: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const [activeTab, setActiveTab] = useState('general');

  const renderUniqueMessage = () => {
    if (activeTab === 'general') {
      return (
        <div className="unique-message">
          <i className="fa fa-lock"></i>
          <div className="unique-message-content">
            <h2>General features</h2>
            <p><strong>Own your data entirely and supercharge your business with our all-in-one platform.</strong></p>
            <p>Bring your domain, and for just $15/month, unlock features that give your local business a competitive edge.</p>
          </div>
        </div>
      );
    } else if (activeTab === 'cafe') {
      return (
        <div className="example-box">
          <div className="example-image">
            <img src={cafeHighlightImage} alt="Cafe Example" />
          </div>
          <div className="example-description">
            <h3>The all-in-one digital solution for cafes</h3>
            <p>Manage your reservations, highlight daily specials, and keep your customers engaged with an integrated events calendar.</p>
            <button onClick={() => window.open('https://design.businessbloc.com/cafe-charm/', '_blank')}>
              View example cafe site now
            </button>
          </div>
        </div>
      );
    } else if (activeTab === 'church') {
      return (
        <div className="example-box">
          <div className="example-image">
            <img src={cafeHighlightImage} alt="Church Example" />
          </div>
          <div className="example-description">
            <h3>The all-in-one digital solution for churches</h3>
            <p>Manage your events, sermons, and volunteer opportunities seamlessly to strengthen your community engagement.</p>
            <button onClick={() => window.open('https://design.businessbloc.com/church-example/', '_blank')}>
              View example church site now
            </button>
          </div>
        </div>
      );
    } else if (activeTab === 'construction') {
      return (
        <div className="example-box">
          <div className="example-image">
            <img src={constructionHighlightImage} alt="Construction Example" />
          </div>
          <div className="example-description">
            <h3>The all-in-one digital solution for construction businesses</h3>
            <p>Showcase your services, display a portfolio of completed projects, and highlight your industry awards and certifications to build trust.</p>
            <button onClick={() => window.open('https://exampleconstruction.com', '_blank')}>
              View example construction site now
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="features-component">
      <div className="tabs">
        <button 
          className={activeTab === 'general' ? 'active' : ''} 
          onClick={() => setActiveTab('general')}
        >
          <div className="tab-image-wrapper">
            <img src={generalFeaturesImage} alt="General Features"/>
            <div className="gradient-overlay"></div>
            <div className="hover-arrow">
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
          <span>General Features</span>
        </button>
        <button 
          className={activeTab === 'cafe' ? 'active' : ''} 
          onClick={() => setActiveTab('cafe')}
        >
          <div className="tab-image-wrapper">
            <img src={cafeHighlightImage} alt="Cafe Features"/>
            <div className="gradient-overlay"></div>
            <div className="hover-arrow">
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
          <span>Cafe Features</span>
        </button>

        <button 
          className={activeTab === 'construction' ? 'active' : ''} 
          onClick={() => setActiveTab('construction')}
        >
          <div className="tab-image-wrapper">
            <img src={constructionHighlightImage} alt="Construction Features"/>
            <div className="gradient-overlay"></div>
            <div className="hover-arrow">
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
          <span>Construction Features</span>
        </button>

        {/*<button 
          className={activeTab === 'church' ? 'active' : ''} 
          onClick={() => setActiveTab('church')}
        >
          <div className="tab-image-wrapper">
            <img src="http://localhost:3000/static/media/business-highlight-cafe-image-1.2913f5a1544f818a01e4.png" alt="Church Features"/>
            <div className="gradient-overlay"></div>
            <div className="hover-arrow">
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
          <span>Church Features</span>
        </button>*/}
      </div>
      <div className="feature-overview">
        <div className="example-container">
          {renderUniqueMessage()}
        </div>
        <div className="features">
          {features[activeTab].map((feature: any, index: number) => (
            <div className="feature-box" key={index}>
              <i className={`fa ${feature.icon} feature-icon`}></i>
              <div className="feature-content">
                <h3>{feature.heading}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpecialityWebsiteFeatures;