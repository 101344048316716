import React from 'react';
import './Pricing.css';
// Import images
import websiteDesignIcon from '../../assets/images/services/foundry.png';
import promptEngineeringIcon from '../../assets/images/services/factory.png';
import payPerClickManagementIcon from '../../assets/images/services/forge.png';
import softwareDevelopmentIcon from '../../assets/images/services/software-development.png';
import { Link } from "react-router-dom";



const Pricing: React.FC<{ businessAuth: any }> = ({ businessAuth }) => {
  return (
    <section className="Services">
    <div className="Services-content">
    <div className="Services-review">
      <div className="Services-review-content">
        <h2>Trusted by <span>500+ businesses</span></h2>
        <p>Are you a local business looking for complete online management for your businesses digital assets for a low monthly fee? Business Bloc has got you covered. From having your website redesigned to groundbreaking access to automated SEO article generation, there isn't much our business management platform cannot do. </p>
      </div>
    </div>
    <div className="Services-grid">
      <div className="Service">
        <div className="Service-content">
          <img className="Service-icon" src={websiteDesignIcon}/>
          <h3>Community</h3>
          <span className="price">Free</span>
          <span className="price-terms"><i>(No charges whatsoever)</i></span>
          <ul className="Service-includes">
            <li><i className="fa-solid fa-check"></i> Generate web designs for any business instantly</li>
            <li><i className="fa-solid fa-check"></i> List your business for free and sell products</li>
            <li><i className="fa-solid fa-check"></i> Task Manager Tool (Manage internal issues easily)</li>
            <li><i className="fa-solid fa-check"></i> User Manager Tool (Onboard your team with ease)</li>
            <li><i className="fa-solid fa-check"></i> Reporting Tool (Generate business-geared reporting)</li>
            <li className="not-included-item"> Web Hosting Tool (Host and manage your website hosting)</li>
            <li className="not-included-item"> Web Maintenance Tool (Dedicated website support)</li>
            <li className="not-included-item"> SEO Tool (AI Article Generation - 5 articles/month)</li>
            <li className="not-included-item"> AI Image Generation - 5 images/month</li>
            <li className="not-included-item">Custom website design</li>
            <li className="not-included-item">Coding adjustments</li>
            <li className="not-included-item">Server optimization</li>
          </ul>
        </div>
        {businessAuth.rank === "registered" ? 
          <a href="/dashboard/my-business" className="price-btn active-plan"><i className="fa fa-check"></i> Active</a>
          :
          <Link to="/get-started" className="price-btn">Get started</Link>
        }
      </div>
      <div className="Service Service-most-popular">
        <div className="Service-content">
          <img className="Service-icon"  src={payPerClickManagementIcon}/>
          <span className="Service-most-popular-tag">MOST POPULAR</span>
          <h3>Web Hosting & AI Design</h3>
          <span className="price">$15/month</span>
          <span className="price-terms">per month/per site</span>
          <ul className="Service-includes">
            <li><i className="fa-solid fa-check"></i> Generate web designs for any business instantly</li>
            <li><i className="fa-solid fa-check"></i> List your business for free and sell products</li>
            <li><i className="fa-solid fa-check"></i> Task Manager Tool (Manage internal issues easily)</li>
            <li><i className="fa-solid fa-check"></i> User Manager Tool (Onboard your team with ease)</li>
            <li><i className="fa-solid fa-check"></i> Reporting Tool (Generate business-geared reporting)</li>
            <li><i className="fa-solid fa-check"></i> Web Hosting Tool (Host and manage your website hosting)</li>
            <li className="not-included-item"> Web Maintenance Tool (Dedicated website support)</li>
            <li className="not-included-item"> SEO Tool (AI Article Generation - 5 articles/month)</li>
            <li className="not-included-item"> AI Image Generation - 5 images/month</li>
            <li className="not-included-item">Custom website design</li>
            <li className="not-included-item">Coding adjustments</li>
            <li className="not-included-item">Server optimization</li>
          </ul>
        </div>  
          {businessAuth.rank === "web-hosting" ? 
            <a href="/dashboard/my-business" className="price-btn active-plan"><i className="fa fa-check"></i> Active</a>
            :
            <Link to="/get-started" className="price-btn">Get started</Link>
          }
      </div>
      <div className="Service">
        <div className="Service-content">
          <img className="Service-icon" src={promptEngineeringIcon}/>
          <h3>Complete</h3>
          <span className="price">$45/month</span>
          <span className="price-terms">per month/per site</span>
          <ul className="Service-includes">
          <li><i className="fa-solid fa-check"></i> Generate web designs for any business instantly</li>
            <li><i className="fa-solid fa-check"></i> List your business for free and sell products</li>
            <li><i className="fa-solid fa-check"></i> Task Manager Tool (Manage internal issues easily)</li>
            <li><i className="fa-solid fa-check"></i> User Manager Tool (Onboard your team with ease)</li>
            <li><i className="fa-solid fa-check"></i> Reporting Tool (Generate business-geared reporting)</li>
            <li><i className="fa-solid fa-check"></i> Web Hosting Tool (Host and manage your website hosting)</li>
            <li><i className="fa-solid fa-check"></i> Web Maintenance Tool (Dedicated website support)</li>
            <li><i className="fa-solid fa-check"></i> SEO Tool (AI Article Generation - 20 articles/month)</li>
            <li><i className="fa-solid fa-check"></i> AI Image Generation - 20 images/month</li>
            <li><i className="fa-solid fa-check"></i> Custom website design</li>
            <li><i className="fa-solid fa-check"></i> Coding adjustments</li>
            <li><i className="fa-solid fa-check"></i> Server optimization</li>
          </ul>
        </div>
        {businessAuth.rank === "complete" ? 
          <a href="/dashboard/my-business" className="price-btn active-plan"><i className="fa fa-check"></i> Active</a>
          :
          <Link to="/get-started" className="price-btn">Get started</Link>
        }
      </div>
    </div>
    </div>
  </section>
  );
};

export default Pricing;
