interface SubscriptionInterface {
  name: string,
  description: string,
  path: string, 
  method: string,
  params: {
    product: string,
    lookup_key: string,
    currency: string,
    unit_amount: number,
    recurring: {
      interval: string | null
    },
    metadata: {
      sample: string | null
    }
  }
}


export const subscriptionPlanDetails: Array<SubscriptionInterface> = [  
  {
    "name": "Complete",
    "description": "Unlock website maintenance and full access to the Bloc dashboard",
    "path": "/v1/prices",
    "method": "post",
    "params": {
      "product": "${basic_product:id}",
      "lookup_key": "price_1NS0JgJkpa2mwrtkEsHHfRso",
      "currency": "cad",
      "unit_amount": 4500,
      "recurring": {
        "interval": "month"
      },
      "metadata": {
        "sample": "fixed-price"
      }
    }
  },
  /*{
    "name": "Growth",
    "path": "/v1/prices",
    "method": "post",
    "params": {
      "product": "${premium_product:id}",
      "lookup_key": "price_1MzrWfJkpa2mwrtkGgs2YrZz",
      "currency": "cad",
      "unit_amount": 13000,
      "recurring": {
        "interval": "month"
      },
      "metadata": {
        "sample": "fixed-price"
      }
    }
  },
  {
    "name": "Enterprise",
    "path": "/v1/prices",
    "method": "post",
    "params": {
      "product": "${premium_product:id}",
      "lookup_key": "price_1MzrYlJkpa2mwrtkprS4fQBN",
      "currency": "cad",
      "unit_amount": 27500,
      "recurring": {
        "interval": "month"
      },
      "metadata": {
        "sample": "fixed-price"
      }
    }
  }*/
]