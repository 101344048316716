import React, { useEffect, useState } from 'react';
import PageHeader from '../PageHeader/PageHeader'
import './Login.css'
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import { Helmet } from 'react-helmet-async';
import * as Yup from "yup";
import heroImage from '../../assets/images/about/about-business-bloc.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

type FormValues = {
  business_email: string,
  business_password: string,
}

const Login: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);

  const initialValues: FormValues = {
    business_email: '',
    business_password: ''
  }
  const validationSchema = Yup.object().shape({
    business_email: 
      Yup.string()
      .email("You must provide an email address such as \"example@gmail.com\"")
      .min(5, "Your email address must be a minimum of 5 characters.")
      .max(60, "Your email address must be a maximum of 60 characters.")
      .required("You must provide an email address that is between 5-60 characters."),
    business_password: Yup.string()
      .min(8, "Your password must be a minimum of 8 characters.")
      .max(35, "Your password must be a maximum of 35 characters.")
      .matches(/[a-zA-Z]/, 'Your password must contain supported language letters.')
      .matches(/[@$!%*#?&]+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .matches(/\d+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .required("Your password may only be between 8-35 characters and contain at least one number and special character."),
  });
  let currentAuthBusiness = businessAuth.status
  useEffect( () => {
    if(currentAuthBusiness == true){
      window.location.href = process.env.REACT_APP_BLOC_DASHBOARD_URL+"";
    }
  }, [businessAuth]);
  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    setDisabled(true)
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/login', { values }, { withCredentials: true }).then((loginResponse: any) => {
      if(loginResponse.status == 200 && loginResponse.data.errorMessage == undefined){
        localStorage.setItem("business_bloc_token", loginResponse.data.token)
        if(loginResponse.business_rank != "registered"){
          window.location.href = process.env.REACT_APP_BLOC_DASHBOARD_URL+"";
        } else {
          window.location.href = "/checkout";
        }
      } else {
        setDisabled(false)
        setErrorMessage(loginResponse.data.errorMessage )
      }
    }).catch((e) => { 
      alert(e)
      setDisabled(false)
      setErrorMessage("Unable to connect to server. Please retry in five minutes.")
    })
  }
  return (
    <div className="Login-Page Spotlight">
      {/* SEO Metadata */}
      <Helmet>
        <title>Login - Business Bloc</title>
        <meta name="description" content="Login to your Business Bloc account to access AI-powered tools and manage your business growth online." />
        <meta name="keywords" content="login, Business Bloc, user account, business dashboard, AI tools access, business management, sign in" />
        <meta property="og:title" content="Login - Business Bloc" />
        <meta property="og:description" content="Access your Business Bloc account and leverage AI-driven solutions to boost your business performance." />
        <meta property="og:image" content={heroImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="Spotlight-Block" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.7), rgba(98, 0, 134, 0.68)), url(${heroImage}), url(${heroImage})` }}>

      </section>
      <section className="Login-Header">
        <div className="Login-Header-Main-Section">
          <div className="Login-Header-content">
              <ul className="Login-Step-List">
                <li className="Login-Step-Indicator">Login</li>
              </ul>
              { /* STEP #1 */ }
              <div className="step">
              <h2>Access your Business Bloc now</h2>
              <Formik
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                >
                  <Form className="Login-Account-Details-Container">
                      { errorMessage != "" ?
                      <div className="Hero-Register-Business-Error-Message">{errorMessage}</div>
                      : "" }
                      <div className="Login-Account-Detail">
                        <ErrorMessage className="Error-Message" name="business_email" component="span" />
                        <Field type="text" placeholder="Email" name="business_email"/>
                      </div>
                      <div className="Login-Account-Detail">
                        <ErrorMessage className="Error-Message" name="business_password" component="span" />          
                        <Field type="password" placeholder="Password" name="business_password"/>
                      </div>
                      <div className="Login-Account-Detail Login-Account-Signin-Button">
                        <button disabled={disabled}>Login <i className="fa-solid fa-arrow-right"></i></button>
                      </div>
                  </Form>
                </Formik>
              </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
