import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {loadStripe} from '@stripe/stripe-js';
import { JSDOM } from 'jsdom';
import DOMPurify from 'dompurify';
import heroImage from  '../../../../assets/images/home/transparent-design-example-3.png';
import validator from 'validator';
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import { PaymentElement, useStripe, useElements, CardElement, Elements } from "@stripe/react-stripe-js";
import { ChromePicker, ColorResult } from 'react-color';

import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import '../Hero.css'
import { create } from 'domain';
import videoFile from '../../../../assets/videos/website-generation/business-bloc-website-generation-in-progress-animation.mp4';
import { AnyAaaaRecord } from 'dns';

interface subscriptionData {
  subscriptionId: string,
  clientSecret: string
}

type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
}

type BusinessDetailsValues = {
  business_name: string,
  business_brief_explanation: string,
  business_contact_number: string,
  business_contact_email: string
}

const stripePromise = loadStripe(process.env.REACT_APP_BLOC_WEBSITE_STRIPE_PUBLISHABLE_KEY!);

// Function to dynamically load the selected font
const loadFont = (fontName: string) => {
  const fontLinkId = `google-font-${fontName.replace(/\s+/g, '-')}`;
  if (!document.getElementById(fontLinkId)) {
    const link = document.createElement('link');
    link.id = fontLinkId;
    link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(/\s+/g, '+')}&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
};

const getCookie = (key: string): string | null => {
  const match = document.cookie.match(new RegExp("(^|;)\\s*" + key + "=\\s*([^;]+)"));
  return match ? decodeURIComponent(match.pop() || '') : "";
}

const SimpleDesign: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  useEffect(() => {
    const genBusinessBlocAuth = async () => {
      //const accessToken = await getCookie("accessToken");
      //alert(JSON.stringify(businessAuth))
      await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + "/commerce/init-checkout", {
        withCredentials: true
      }).then( async(response: any) => { 
        //alert(JSON.stringify(response.data))
        const value = response.data.client_secret
        setClientSecret(value);
        //console.log(value)
      })
    };
    //if(businessAuth.status == true){
      genBusinessBlocAuth();
    //} else {
     // window.location.href = '/login'
    //}
  }, []);

  return (
    <>
      { appLoading == false ?
      <>
      <Elements stripe={stripePromise}>
        <HeroDetails 
          businessAuth={businessAuth}
          appLoading={appLoading}
        />
      </Elements>
      </>
      : " "}
    </>
  );
}


const HeroDetails: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);


  const [headerType, setHeaderType] = useState(() => {
    const storedHeaderType = localStorage.getItem('headerType');
    console.log("Stored Header Type:", storedHeaderType); // Check if localStorage value is retrieved
    return storedHeaderType || 'Non-Transparent'; // Use 'Non-Transparent' as default if localStorage value is null or undefined
  });

  const [footerType, setFooterType] = useState(() => {
    const storedFooterType = localStorage.getItem('footerType');
    console.log("Stored Footer Type:", storedFooterType); // Check if localStorage value is retrieved
    return storedFooterType || 'Dual-Section'; // Use '4 Columns' as default if localStorage value is null or undefined
  });

  const [selectedPages, setSelectedPages] = useState<string[]>(() => {
    const storedSelectedPages = localStorage.getItem('selectedPages');
    const parsedPages = storedSelectedPages ? JSON.parse(storedSelectedPages) : [];
    
    // Ensure "home" is always included
    if (!parsedPages.includes('home')) {
      parsedPages.push('home');
    }
    return parsedPages;
  });

  const [selectedServices, setSelectedServices] = useState<string[]>(() => {
    const storedSelectedServices = localStorage.getItem('selectedServices');
    const parsedServices = storedSelectedServices ? JSON.parse(storedSelectedServices) : [];
    return parsedServices;
  });

  const [businessName, setBusinessName] = useState('');
  const [businessBriefExplaination, setBusinessBriefExplaination] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  // State to track the custom niche entered by the user
  const [customNiche, setCustomNiche] = useState('');
  const [aiDraftWebsiteUrl, setAiDraftWebsiteUrl] = useState('')
  const [aiDraftWebsiteUrlScreenshotUrl, setAiDraftWebsiteUrlScreenshotUrl] = useState('')

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const [allowCreditCardFieldInput, setAllowCreditCardFieldInput] = useState<boolean | false>(false);
  const [messages, setMessages] = useState('');
  const [error, setError] = useState<string | "">("");
  const [name, setName] = useState('Jenny Rosen');
  // Set price lookup key for website design + hosting service
  const [priceLookupKey, setPriceLookupKey] = useState<string>('price_1QLHJqJkpa2mwrtkqTQKy8Tn');
  const [subscriptionProcessingLoading, setSubscriptionProcessingLoading] = useState<boolean>(false);
  const [paymentIntent, setPaymentIntent] = useState();
  let [displayPrice, setDisplayPrice] = useState('' as string || null); 
  let businessBlocAuthToken = localStorage.getItem("business_bloc_token")
  let currentAuthEntity = businessAuth.entity

  /* Color / Logo / Font */
  const [primaryColor, setPrimaryColor] = useState('#3a3da3');
  const [secondaryColor, setSecondaryColor] = useState('#2aeff3');
  const [darkFontColor, setDarkFontColor] = useState('#000000');
  const [lightFontColor, setLightFontColor] = useState('#ffffff');
  const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false);
  const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false);
  const [showDarkFontColorPicker, setShowDarkFontColorPicker] = useState(false);
  const [showLightFontColorPicker, setShowLightFontColorPicker] = useState(false);
  const [logo, setLogo] = useState<any>(null);
  const [headingFont, setHeadingFont] = useState('Outfit');
  const [contentFont, setContentFont] = useState('Outfit');
  const [previewUrl, setPreviewUrl] = useState<any>(null);
  const [websiteBrandType, setWebsiteBrandType] = useState('');
  // Brand type sub categories
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [fullListOfDrafts, setFullListOfDrafts] = useState<string[]>([]);
  const [selectedDraft, setSelectedDraft] = useState<any>(null);
  const [websiteTypePages, setWebsiteTypePages] = useState<string[]>([]);
  const [businessSubCategory, setBusinessSubCategory] = useState(subCategories[0]);
  // Services displayed based on brand sub category
  const [services, setServices] = useState<string[]>([]);
  // Edit Pages Popup
  const [isEditPagePopupOpen, setIsEditPagePopupOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState("services");
  const fileInputRef = useRef<any>(null); // Ref for the hidden file input
  const [websiteStatusLoading, setWebsiteStatusLoading] = useState(true); 
  const [designErrorMessage, setDesignErrorMessage] = useState("");


  // Website Building Options
  // Options are: "fast" or "detailed"
  const [blocWebsiteBuildingSetting, setBlocWebsiteBuildingSetting] = useState("fast");

  const handlePageSelect = (page: any) => {
    setSelectedPage(page);
  };

  const containerRef = useRef<HTMLDivElement>(null); // Typed reference to the container

  // Toggle popup visibility
  const toggleEditPagePopup = () => {
    setIsEditPagePopupOpen(!isEditPagePopupOpen);
  };

  // Handle service selection for Services page
  const handleServiceSelection = (service: string) => {

    // Determine if the page is already selected
    const isPageSelected = selectedServices.includes(service);
  
    // Create a new array based on the current selectedPages state
    let updatedSelectedServices: string[];
  
    if (isPageSelected) {
      // Remove the page from the array if already selected
      updatedSelectedServices = selectedServices.filter(selectedService => selectedService !== service);
    } else {
      // Add the page to the array if not selected
      updatedSelectedServices = [...selectedServices, service];
    }
  
    // Update the state with the new array without triggering a re-render
    setSelectedServices(updatedSelectedServices);

    localStorage.setItem('selectedServices', JSON.stringify(updatedSelectedServices));
  };

  // Handle Logo Upload

  const handleLogoUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveLogo = () => {
    setLogo(null);
    setPreviewUrl(null);
  };

  /* Primary & Secondary Brand Colours */

  const handlePrimaryColorChange = (color: ColorResult) => {
    setPrimaryColor(color.hex);
  };

  const handleSecondaryColorChange = (color: ColorResult) => {
    setSecondaryColor(color.hex);
  };

  /* Dark & light font color */

  const handleLightFontColorChange = (color: ColorResult) => {
    setLightFontColor(color.hex);
  };

  const handleDarkFontColorChange = (color: ColorResult) => {
    setDarkFontColor(color.hex);
  };

  const handlePageSelection = (page: string) => {
    // Determine if the page is already selected
    const isPageSelected = selectedPages.includes(page);
  
    // Create a new array based on the current selectedPages state
    let updatedSelectedPages: string[];
  
    if (isPageSelected) {
      // Remove the page from the array if already selected
      updatedSelectedPages = selectedPages.filter(selectedPage => selectedPage !== page);
    } else {
      // Add the page to the array if not selected
      updatedSelectedPages = [...selectedPages, page];
    }
  
    // Update the state with the new array without triggering a re-render
    setSelectedPages(updatedSelectedPages);
  
    // Update localStorage outside the state update to avoid unnecessary re-renders
    localStorage.setItem('selectedPages', JSON.stringify(updatedSelectedPages));
  };
  
  
  useEffect(() => {


    const tokenString = localStorage.getItem('selectedWebsiteData');
    // Selected header + Footer
    // Step 1: Retrieve token from local storage

    if (tokenString) {
        // Step 2: Parse token string into an object
        const token: {
            websiteType: string;
            businessSubCategory: string;
            currentStep: number;
            headerType: string;
            footerType: string;
            primaryColor: string;
            secondaryColor: string;
            headingFont: string;
            contentFont: string;
            selectedPages: string[];
            selectedServices: string[];
            business_name: string;
            business_brief_explanation: string;
            contact_number: string;
            contact_email: string;
        } = JSON.parse(tokenString);

        setWebsiteBrandType(token.websiteType ?? websiteType);
        const matchedOption = options.find(option => option.name.toLowerCase() === token.websiteType.toLowerCase());

        // Based on website type, we are going to set the conditional options for the website type, not user selections.
        if (matchedOption) {
          setWebsiteBrandType(matchedOption.name); // Set the website brand type (e.g., 'Cafe')
          setSubCategories(matchedOption.subCategories); // Set the subcategories based on the found option
          setWebsiteTypePages(matchedOption.pages);
          setServices(matchedOption.services);
        } else {
          console.warn('No matching website type found');
        }
        
        // Set previous user's option selections
        setFooterType(token.footerType ?? footerType);
        setHeaderType(token.headerType ?? headerType);
        setBusinessName(token.business_name ?? businessName);
        setBusinessSubCategory(token.businessSubCategory ?? businessSubCategory)
        setBusinessBriefExplaination(token.business_brief_explanation ?? businessBriefExplaination);
        setContactNumber(token.contact_number ?? contactNumber);
        setContactEmail(token.contact_email ?? contactEmail);
        setPrimaryColor(token.primaryColor ?? primaryColor);
        setSecondaryColor(token.secondaryColor ?? secondaryColor);
        setHeadingFont(token.headingFont ?? headingFont);
        setContentFont(token.contentFont ?? contentFont);
        loadFont(token.headingFont ?? headingFont);
        loadFont(token.contentFont ?? contentFont);

        // Step 4: Stringify the updated object
        //const updatedTokenString = JSON.stringify(token);

        // Step 5: Save the updated token back to local storage
        //localStorage.setItem('selectedWebsiteData', updatedTokenString);
    }

    // Selected pages & services (arrays)
    localStorage.setItem('selectedPages', JSON.stringify(selectedPages));
    localStorage.setItem('selectedServices', JSON.stringify(selectedServices));
    const storedStep = localStorage.getItem('selectedWebsiteData');
    axios.get(process.env.REACT_APP_BLOC_SERVER_URL + "/ai/retrieve-generation-status", {
      params: businessAuth,
    }).then( async(retrieveGenerationStatus: any) => {
      //alert(JSON.stringify(retrieveGenerationStatus.data))
      if (retrieveGenerationStatus.data.draft_url != null){        
        setAiDraftWebsiteUrlScreenshotUrl(retrieveGenerationStatus.data.draft_url_screenshot_url)
        setAiDraftWebsiteUrl(retrieveGenerationStatus.data.draft_url)
        if (businessAuth.rank == "web-hosting" || businessAuth.rank == "complete") {
          setCurrentStep(7)

        } else {
          setCurrentStep(5)
        }
        setWebsiteStatusLoading(false)
        createSubscription("price_1QLHJqJkpa2mwrtkqTQKy8Tn");
        // Lists of drafts 
        const drafts = retrieveGenerationStatus.data.full_list_of_drafts || [];
        setFullListOfDrafts(drafts);
    
        // Default to the first draft
        if (drafts.length > 0) {
          // Clone the drafts array to avoid mutating state directly
          const updatedDrafts = [...drafts];

    
          try {
            // Parse and update the first draft's draft_url_screenshot_url
            const firstDraft = updatedDrafts[0];

            // Update the state with the modified draft
            setSelectedDraft(firstDraft);
          } catch (error) {
            console.error("Error parsing draft_url_screenshot:", error);
          }
        }
        return;
      } else if(retrieveGenerationStatus.data.status == true){
        setCurrentStep(5)
        setWebsiteStatusLoading(false)
        createSubscription("price_1QLHJqJkpa2mwrtkqTQKy8Tn");
        return;
      }
      if (storedStep) {
        const { currentStep: storedCurrentStep } = JSON.parse(storedStep);
        switch (storedCurrentStep) {
          case 2:
            setCurrentStep(2);
            setWebsiteStatusLoading(false)
            break;
          case 3:
            setCurrentStep(3);
            setWebsiteStatusLoading(false)
            break;
          case 4:
            // Forward to step 4 if stored currentStep is 4
            setCurrentStep(4);
            setWebsiteStatusLoading(false)
            break;
          default:
            setCurrentStep(1);
            setWebsiteStatusLoading(false)
        }
      }
      if(websiteStatusLoading == true){
        setWebsiteStatusLoading(false)
      }
    })

    const handleWindowResize = () => {
      console.log('Window resized');
      if (containerRef.current) {
        containerRef.current.scrollLeft = 0; // Reset scroll position to the start
        setCurrentIndex(0); // Reset current index to 0
      }
    };

    window.addEventListener('resize', handleWindowResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };


  }, []);

  // Handle selection change
  const handleDraftSelectChange = (event: any) => {
    const selectedIndex = event.target.value;
  
    // Get the selected draft
    const selectedDraft: any = fullListOfDrafts[selectedIndex];


    //alert(selectedIndex)
    //alert(JSON.stringify(fullListOfDrafts[selectedIndex]))
    // Parse and update the draft_url_screenshot
    if (selectedDraft.draft_url_screenshot_url) {
      try {
        const parsedData = JSON.parse(selectedDraft.draft_url_screenshot_url);
  
        // Extract the URL if the parsed data is valid
        if (Array.isArray(parsedData) && parsedData.length > 0) {
          selectedDraft.draft_url_screenshot_url = parsedData[0].url;
        } else {
          console.error("Invalid data format or empty array:", parsedData);
        }
      } catch (error) {
        console.error("Error parsing draft_url_screenshot_url:", error);
      }
    }
  
    // Update the state with the modified draft
    setSelectedDraft(selectedDraft);
  };

  const changeDesignSettings = (e: any) => {
    setCurrentStep(1)
  };

  const handleHeadingFontChange = (e: any) => {
    const newFont = e.target.value;
    setHeadingFont(newFont);
    loadFont(newFont); // Load the new font when the user changes the selection
  };

  const handleContentFontChange = (e: any) => {
    const newFont = e.target.value;
    setContentFont(newFont);
    loadFont(newFont); // Load the new font when the user changes the selection
  };

  const handleHeaderTypeSelection = (type: string) => {
    setHeaderType(type);
  };

  const handleFooterTypeSelection = (type: string) => {
    setFooterType(type);
  };


const handleNextStep = (formik: any | null) => {
  console.log("Here we go: ")
  console.log(formik)
  // Save relevant data to localStorage based on the current step
  switch (currentStep) {
    case 1:
      localStorage.setItem('selectedWebsiteData', JSON.stringify({
        ...JSON.parse(localStorage.getItem('selectedWebsiteData') || '{}'),
        currentStep: currentStep + 1
      }));
      break;
    case 2:
      localStorage.setItem('selectedWebsiteData', JSON.stringify({
        ...JSON.parse(localStorage.getItem('selectedWebsiteData') || '{}'),
        currentStep: currentStep + 1,
        businessSubCategory: businessSubCategory,
        headerType: headerType,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        darkFontColor: darkFontColor,
        lightFontColor: lightFontColor,
        headingFont: headingFont,
        contentFont: contentFont,
        footerType: footerType,
        selectedPages: selectedPages,
        selectedServices: selectedServices
      }));
      break;
    case 3:
      console.log(formik.values);
      localStorage.setItem('selectedWebsiteData', JSON.stringify({
        ...JSON.parse(localStorage.getItem('selectedWebsiteData') || '{}'),
        currentStep: currentStep + 1,
        business_name: formik.values.business_name,
        business_brief_explanation: formik.values.business_brief_explanation,
        contact_number: formik.values.business_contact_number,
        contact_email: formik.values.business_contact_email
      }));
      setContactEmail(formik.values.business_contact_email)
      setContactNumber(formik.values.business_contact_number)
      setBusinessBriefExplaination(formik.values.business_brief_explanation)
      setBusinessName(formik.values.business_name)
      break;
    case 4:
      // No need to save data for step 4 here, it's handled after registration
      break;
    default:
      // Handle other steps if needed
  }

  setCurrentStep(currentStep + 1);

  if (currentStep + 1 === 4) {
    createSubscription("price_1OxfbRJkpa2mwrtkQWJCTWA7");
  }
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const handlePreviousStep = () => {
  // Move to previous step
  setCurrentStep(currentStep - 1);

  // Update localStorage with relevant data for the previous step
  switch (currentStep) {
    case 2:
      localStorage.setItem('selectedWebsiteData', JSON.stringify({
        ...JSON.parse(localStorage.getItem('selectedWebsiteData') || '{}'),
        headerType: headerType,
        footerType: footerType,
        selectedPages: selectedPages,
        selectedServices: selectedServices
      }));
      break;
    default:
      // Handle other steps if needed
  }
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

  const handleSubCategoryChange = (event: any) => {
    setBusinessSubCategory(event.target.value);
  };

 // Updated handleOptionClick function
  // Updated handleOptionClick function
  const handleOptionClick = (option: any) => {
    if (option === 'search') {
      const searchInput = document.querySelector('.SearchBox input') as any;
      const searchQuery = searchInput ? searchInput.value.trim().toLowerCase() : '';
      if (searchQuery) {
        handleCustomNiche(searchQuery);
      } else {
        alert('Please enter a search query.');
      }
    } else {
      // Predefined option selected. Update local storage and state accordingly.
      localStorage.setItem(
        'selectedWebsiteData',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('selectedWebsiteData') || '{}'),
          websiteType: option.name.toLowerCase()
        })
      );
      setSelectedOption(option.name);
      setWebsiteTypePages(option.pages || []);
      setWebsiteBrandType(option.name);
      setSubCategories(option.subCategories || []);
      setServices(option.services || []);
      // Set the default subcategory if available.
      if (option.subCategories && option.subCategories.length > 0) {
        setBusinessSubCategory(option.subCategories[0]);
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  

  

 // Initialize an instance of stripe.
 const stripe = useStripe();
 const elements = useElements();
 
 const [subscriptionData, setSubscriptionData] = useState<subscriptionData>({
   subscriptionId: '',
   clientSecret: ''
 })

 
 React.useEffect(() => {
  setPriceLookupKey('price_1QLHJqJkpa2mwrtkqTQKy8Tn');
  const timer = setTimeout(() => setIsVisible(true), 100);
  return () => clearTimeout(timer);
}, [])

  
 const createSubscription = async (priceId: any) => {
  setPaymentInProgress(true)
   const genSubscription = async () => {
     setSubscriptionProcessingLoading(true)
     let currentAuthBusiness = businessAuth.email
     if(currentAuthBusiness != ''){
      //alert("Run it")
       await axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/commerce/create-subscription', {
         priceId,
         currentAuthBusiness
       }, {
         withCredentials: true
       }
       ).then((subscriptionResponse: any) => {
         if(subscriptionResponse.status == 200){
           const subscriptionId = subscriptionResponse.data.subscriptionId
           const clientSecret = subscriptionResponse.data.clientSecret
           //alert(clientSecret)
           if(subscriptionId && clientSecret){
              //alert("Subsciption and client secret key active")
             setSubscriptionData({ subscriptionId, clientSecret });
             setAllowCreditCardFieldInput(true)
           } else {
             setAllowCreditCardFieldInput(false)
             console.log("Error: #908")
           }
         }          
       })
     } else {
       console.log("Not authorized")
     }
   }
   genSubscription().then(() => {
     setSubscriptionProcessingLoading(false)
     setPaymentInProgress(false)

   })
 }










  const initialValues: FormValues = {
    business_name: businessName,
    business_email: contactEmail,
    business_password: ''
  }

  const initialBusinessDetails: BusinessDetailsValues = {
    business_name: businessName,
    business_brief_explanation: businessBriefExplaination,
    business_contact_number: contactNumber,
    business_contact_email: contactEmail
  }

  const validationSchema = Yup.object().shape({
    business_name: 
      Yup.string()
      .min(2, "Your business name must be at least 2 characters.")
      .max(50, "Your businss name must be a maximum of 50 characters.")
      .matches(/^[\w '-]*[^\W_][\w '-]*$/, "Your business name may only contain letters, numbers, spaces, dashes, and apostrophes.")
      .required("You must provide a business name that is between 2-50 characters."),
    business_brief_explanation: 
      Yup.string()
      .min(10, "Your business description must be at least 10 characters.")
      .max(300, "Your business description must be a maximum of 300 characters.")
      .matches(/^[\w .,!?()]*$/, "Your business description may only contain letters, numbers, punctuation, and spaces.")
      .required("You must provide a business description that is between 10-300 characters."),
    business_email: 
      Yup.string()
      .email("You must provide an email address such as \"example@gmail.com\"")
      .min(5, "Your email address must be a minimum of 5 characters.")
      .max(60, "Your email address must be a maximum of 60 characters.")
      .required("You must provide an email address that is between 5-60 characters."),
    business_contact_number:
      Yup.string()
      .matches(/^[0-9+\-()\s]*$/, "Your contact number may only contain numbers, spaces, and the symbols +, -, ().")
      .min(7, "Your contact number must be a minimum of 7 characters.")
      .max(15, "Your contact number must be a maximum of 15 characters.")
      .required("You must provide a contact number that is between 7-15 characters."),  
  });

  const registerNewBusinessValidationSchema = Yup.object().shape({
    business_name: 
      Yup.string()
      .min(2, "Your business name must be at least 2 characters.")
      .max(50, "Your businss name must be a maximum of 50 characters.")
      .matches(/^[\w '-]*[^\W_][\w '-]*$/, "Your business name may only contain letters, numbers, spaces, dashes, and apostrophes.")
      .required("You must provide a business name that is between 2-50 characters."),
    business_email: 
      Yup.string()
      .email("You must provide an email address such as \"example@gmail.com\"")
      .min(5, "Your email address must be a minimum of 5 characters.")
      .max(60, "Your email address must be a maximum of 60 characters.")
      .required("You must provide an email address that is between 5-60 characters."),
    business_password: Yup.string()
      .min(8, "Your password must be a minimum of 8 characters.")
      .max(35, "Your password must be a maximum of 35 characters.")
      .matches(/[a-zA-Z]/, 'Your password must contain supported language letters.')
      .matches(/[@$!%*#?&]+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .matches(/\d+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .required("Your password may only be between 8-35 characters and contain at least one number and special character."),
  });

  const Step3AboutWebsiteSubmission = (values: BusinessDetailsValues, helpers: FormikHelpers<BusinessDetailsValues>) => {

  } 
  
  const registerNewBusiness = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    setDisabled(true)
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/create', 
    { 
      values 
    }, 
    {
      withCredentials: true,
    }
    ).then((successAccountCreated) => {
      //alert(JSON.stringify(successAccountCreated))
      if(successAccountCreated.status == 200 && successAccountCreated.data.errorMessage == undefined){
        localStorage.setItem("business_bloc_token", successAccountCreated.data.token)   
        window.location.href = "/create-business-website";
      } else {
        setDisabled(false)
        setErrorMessage(successAccountCreated.data.errorMessage )
      }
    }).catch((e) => { 
      //alert(e)
      setDisabled(false)
      setErrorMessage("Unable to connect to server. Please retry in five minutes.")
    })
  } 

  /* OLD PAYMENT PLUGIN SUBMISSION */
  /*const handleSubmit = async (e: any) => {
    e.preventDefault();
    setPaymentInProgress(true)
    const cardElement = elements!.getElement(CardElement);
    // Use card Element to tokenize payment details
    const data = await stripe!.confirmCardPayment(subscriptionData.clientSecret, {
      payment_method: {
        card: cardElement!,
        billing_details: {
          name: name,
        }
      }
    }).then((response) => {
      if(response.paymentIntent?.status == 'succeeded'){
        const formData = new FormData();
        formData.append('auth', JSON.stringify(businessAuth.entity));
        formData.append('headerType', headerType);
        formData.append('footerType', footerType);
        formData.append('primaryColor', primaryColor);
        formData.append('secondaryColor', secondaryColor);
        formData.append('websiteType', websiteBrandType);
        if (logo) {
          formData.append('logo', logo);
        }
        formData.append('headingFont', headingFont);
        formData.append('contentFont', contentFont);
        formData.append('selectedPages', JSON.stringify(selectedPages));
        formData.append('briefExplaination', businessBriefExplaination);
        formData.append('contactNumber', contactNumber);
        formData.append('contactEmail', contactEmail);

        handleNextStep(5)
        axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/ai/generate-website', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((aiGenerationStatus: any) => {
          //alert("Final Fantasy 7 Victory Music")   
          //alert(JSON.stringify(aiGenerationStatus))
          setCurrentStep(6)   
          setAiDraftWebsiteUrl(aiGenerationStatus.data.draftUrl) 
        })
      } else {
        alert("Payment not successful")
        setError(response.paymentIntent?.status || 'Error')
      }
    });
    setPaymentInProgress(false)
  }*/

  // Convert selected services + sub category to ID foramt
  function convertToIdFormat(value: any) {
    // Function to convert a string to the desired ID format
    const format = (str: any) => str.replace(/\s+/g, '-').replace(/'/g, '').toLowerCase();
  
    // If value is an array, map through it to convert each item
    if (Array.isArray(value)) {
      return value.map(format);
    }
  
    // If it's a single string, return the formatted version
    return format(value);
  }


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setPaymentInProgress(true)
    const cardElement = elements!.getElement(CardElement);
    // Use card Element to tokenize payment details
    const data = await stripe!.confirmCardPayment(subscriptionData.clientSecret, {
      payment_method: {
        card: cardElement!,
        billing_details: {
          name: name,
        }
      }
    }).then((response) => {
      if(response.paymentIntent?.status == 'succeeded'){
        axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/update-rank', { 
            referral_id: localStorage.getItem("referral_id")?.toString(),
            sender: businessAuth.entity,
            business_email: businessAuth.email, 
            business_rank_id: response.paymentIntent.amount 
          }, {
          withCredentials: true
        }).then((businessRankStatus) => {
          if(businessRankStatus.status == 200 && businessRankStatus.data.errorMessage == undefined){
            localStorage.setItem("business_bloc_token", businessRankStatus.data.token);
            // If referral ID exists, create referral in DB and remove from localStorage
            if(localStorage.getItem("referral_id")){
              localStorage.removeItem("referral_id")
            }
            window.location.href = process.env.REACT_APP_BLOC_DASHBOARD_URL+"/"
          }          
        })
      } else {
        setError(response.paymentIntent?.status || 'Error')
      }
    })
  }


  const handleDesignGeneration = async (e: any) => {
    e.preventDefault();
    setPaymentInProgress(true)
    const formData = new FormData();
    formData.append('auth', JSON.stringify(businessAuth.entity));
    formData.append('headerType', headerType);
    formData.append('footerType', footerType);
    formData.append('primaryColor', primaryColor);
    formData.append('secondaryColor', secondaryColor);
    formData.append('lightFontColor', lightFontColor);
    formData.append('darkFontColor', darkFontColor);
    formData.append('websiteType', websiteBrandType.toLowerCase());
    //alert("Website brand type: " + websiteBrandType)
    // Converted subcategory ID
    const businessSubCategoryId = convertToIdFormat(businessSubCategory);
    formData.append('businessSubCategory', businessSubCategoryId);
    //alert("Sub category ID: " + businessSubCategoryId)
    if (logo) {
      formData.append('logo', logo);
    }
    formData.append('headingFont', headingFont);
    formData.append('contentFont', contentFont);
    formData.append('selectedPages', JSON.stringify(selectedPages));
    // Converted selected services
    const selectedServiceIds = convertToIdFormat(selectedServices);
    formData.append('selectedServices', JSON.stringify(selectedServiceIds));
    formData.append('businessName', businessName);
    formData.append('businessBriefExplaination', businessBriefExplaination);
    formData.append('contactNumber', contactNumber);
    formData.append('contactEmail', contactEmail);
    setCurrentStep(6)
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/ai/generate-website', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((aiGenerationStatus: any) => {
      setCurrentStep(5)
      //alert(aiGenerationStatus.data)

      if(aiGenerationStatus.data.key == undefined && aiGenerationStatus.data.key != "error"){
        setFullListOfDrafts(aiGenerationStatus.data.full_list_of_drafts);
        setSelectedDraft({
          draft_url: aiGenerationStatus.data.draftUrl,
          draft_url_screenshot_url: aiGenerationStatus.data.draft_url_screenshot_url[0].url
        })
        setAiDraftWebsiteUrlScreenshotUrl(aiGenerationStatus.data.draft_url_screenshot_url[0].url) 
        setAiDraftWebsiteUrl(aiGenerationStatus.data.draftUrl) 
        createSubscription("price_1QLHJqJkpa2mwrtkqTQKy8Tn");
      } else if (aiGenerationStatus.data.key == "error"){
        setDesignErrorMessage(aiGenerationStatus.data.message)
      }
    });
    setPaymentInProgress(false)
  }
  
  const handleCustomNiche = (niche: string) => {
    if (niche) {
      // Retrieve existing selected website data from localStorage
      const existingData = JSON.parse(localStorage.getItem('selectedWebsiteData') || '{}');
      // Merge the new custom niche with existing data
      const updatedData = {
        ...existingData,
        websiteType: niche.toLowerCase(),
        customNiche: niche
      };
      // Save the updated data to localStorage
      localStorage.setItem('selectedWebsiteData', JSON.stringify(updatedData));
      setSelectedOption(niche); // Set the selected option to the custom niche
      setCurrentStep(currentStep + 1);
    } else {
      alert('Please enter a custom niche.');
    }
  };

  const options = [
    { 
      name: 'Cafe', 
      icon: 'fas fa-coffee', 
      keywords: ['coffee', 'restaurant', 'cafe', 'food', 'drinks'],
      pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'menu', 'contact'],
      subCategories: [
        'Coffee Shop',
        'Bistro',
        'Tea House',
        'Bakery Cafe',
        'Juice Bar',
        'Cafe and Co-working Space',
        'Specialty Coffee Bar',
        'Brunch Cafe'
      ],
      services: [
        "Coffee-based Drinks",
        "Specialty Coffee",
        "Tea",
        "Seasonal Drinks",
        "Breakfast Items",
        "Catering",
        "Snacks and Sides",
        "Desserts"
      ]
    },
    { 
      name: 'Church', 
      icon: 'fa-solid fa-church', 
      keywords: ['worship', 'religion', 'temple', 'baptist', 'evangelical', 'jesus', 'god', 'church', 'faith'],
      pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
      subCategories: [
        'Baptist Church',
        'Catholic Church',
        'Non-denominational Church',
        'Evangelical Church',
        'Orthodox Church',
        'Pentecostal Church',
        'Methodist Church',
        'Lutheran Church'
      ],
      services: [
        "Sunday Worship",
        "Youth Ministry",
        "Community Outreach",
        "Counseling Services",
        "Bible Study",
        "Children's Programs",
        "Charity Events",
        "Missionary Support"
      ]
    },
    { 
      name: 'Construction', 
      icon: 'fa-solid fa-hammer', 
      keywords: ['construction', 'concrete', 'electrician', 'plumbing', 'plumber', 'energy', 'drywall', 'framing', 'hvac', 'heating'], 
      pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
      subCategories: [
        'Residential Construction',
        'Commercial Construction',
        'Industrial Construction',
        'Civil Engineering',
        'Electrical Contractors',
        'Plumbing Services',
        'HVAC Installation',
        'Roofing Contractors'
      ],
      services: [
        "New Home Construction",
        "Commercial Building",
        "Home Renovations",
        "Roofing and Repairs",
        "Plumbing and Electrical",
        "Landscaping and Excavation",
        "Demolition Services",
        "Interior Design"
      ]
    },
  { 
    name: 'Lawyer', 
    icon: 'fa-solid fa-scale-balanced', 
    keywords: ['law', 'legal', 'justice', 'attorney', 'lawyer'], 
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Criminal Defence',
      'Corporate Law',
      'Family Law',
      'Personal Injury',
      'Real Estate Law',
      'Immigration Law',
      'Employment Law'
    ],
    services: [
      'Legal Consultation',
      'Contract Drafting',
      'Litigation Services',
      'Dispute Resolution'
    ]
  },
  { 
    name: 'Restaurant', 
    icon: 'fas fa-utensils', 
    keywords: ['food', 'dining', 'restaurant', 'cuisine', 'eatery'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'menu', 'contact'],
    comingSoon: true,
    subCategories: [
      'Fine Dining',
      'Casual Dining',
      'Fast Food',
      'Food Truck',
      'Pizzeria',
    ],
    services: [
      'Menu Creation',
      'Catering Services',
      'Private Events',
      'Online Ordering'
    ]
  },
  { 
    name: 'Portfolio', 
    icon: 'fas fa-briefcase', 
    keywords: ['portfolio', 'showcase', 'freelancer', 'work', 'projects'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Freelancer Portfolio',
      'Photography Portfolio',
      'Videography Portfolio',
      'Developer Portfolio',
    ],
    services: [
      'Project Presentation',
      'Client Testimonials',
      'Skills Showcase',
      'Case Studies'
    ]
  },
  { 
    name: 'Real Estate', 
    icon: 'fas fa-home', 
    keywords: ['real estate', 'property', 'housing', 'homes', 'realtor'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Residential Real Estate',
      'Commercial Real Estate',
      'Developer',
      'Property Management',
      'Luxury'
    ],
    services: [
      'Property Listings',
      'Virtual Tours',
      'Real Estate Consulting',
      'Property Valuations'
    ]
  },
  { 
    name: 'Consulting', 
    icon: 'fas fa-comments', 
    keywords: ['consulting', 'advice', 'strategy', 'business', 'consultant'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'HR Consulting',
      'IT Consulting',
      'Environmental Consulting'
    ],
    services: [
      'Business Strategy',
      'Market Analysis',
      'Financial Planning',
      'Performance Optimization'
    ]
  },
  { 
    name: 'Gym', 
    icon: 'fas fa-dumbbell', 
    keywords: ['fitness', 'gym', 'workout', 'training', 'health'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Yoga Studio',
      'Pilates Studio',
      'Combat Training',
      'Fitness Center'
    ],
    services: [
      'Personal Training',
      'Group Classes',
      'Nutrition Coaching',
      'Membership Plans'
    ]
  },
  { 
    name: 'Education', 
    icon: 'fas fa-graduation-cap', 
    keywords: ['education', 'school', 'courses', 'learning', 'university'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Primary School',
      'High School',
      'University',
      'Online Learning Platform',
      'Tutoring',
      'Private School'
    ],
    services: [
      'Online Courses',
      'Private Tutoring',
      'Curriculum Development',
      'Education Consulting'
    ]
  },
  { 
    name: 'Medical', 
    icon: 'fas fa-hospital', 
    keywords: ['medical', 'health', 'doctor', 'clinic', 'hospital'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Family Clinic',
      'Dental Clinic',
      'Eye Care Center',
    ],
    services: [
      'Medical Consultation',
      'Specialty Services',
      'Preventive Care',
      'Health Screenings'
    ]
  },
  { 
    name: 'Non-Profit', 
    icon: 'fas fa-hand-holding-heart', 
    keywords: ['non-profit', 'charity', 'donations', 'fundraising', 'community'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Charitable Foundation',
      'Community Organization',
      'Environmental Group',
      'Animal Welfare Group',
      'Religious Non-profit'
    ],
    services: [
      'Fundraising Events',
      'Volunteer Programs',
      'Community Outreach',
      'Advocacy Campaigns'
    ]
  },
  { 
    name: 'Beauty', 
    icon: 'fas fa-spa', 
    keywords: ['beauty', 'spa', 'salon', 'cosmetics', 'skincare'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Hair Salon',
      'Nail Salon',
      'Day Spa',
      'Makeup Artist',
      'Barbershop'
    ],
    services: [
      'Hair Styling',
      'Facial Treatments',
      'Manicure & Pedicure',
      'Makeup Application'
    ]
  },
  {
    name: 'Retail', 
    icon: 'fas fa-shopping-cart', 
    keywords: ['shopping', 'store', 'commerce', 'goods', 'products'],
    pages: ['home', 'services', 'about', 'team', 'shop', 'events', 'contact'],
    comingSoon: true,
    subCategories: [
      'Clothing Store',
      'Supermarket'
    ],
    services: [
      'Product Listings',
      'Promotions & Sales',
      'Online Shopping',
      'Customer Support'
    ]
  }
];
  
  const filteredOptions = options.filter(option =>
    option.keywords.some(keyword => keyword.toLowerCase().includes(customNiche.toLowerCase()))
  );
  
  const handleSearchEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchInput = document.querySelector('.SearchBox input') as HTMLInputElement;
      handleOptionClick('search');
    }
  };

  let totalAmountOfSteps 
  if(aiDraftWebsiteUrl != null){
    totalAmountOfSteps = 5
  } else if(businessAuth.status == true){
    totalAmountOfSteps = 4
  } else {
    totalAmountOfSteps = 5
  }

  /*const isOptionSelected = (option: any) => {
    const selectedWebsiteData = JSON.parse(localStorage.getItem('selectedWebsiteData') || '{}');
    return selectedWebsiteData.websiteType === option.toLowerCase();
  };*/

  const websiteDataString = localStorage.getItem('selectedWebsiteData');
  let websiteType = '';

  if (websiteDataString) {
    try {
      const websiteData = JSON.parse(websiteDataString);
      if (websiteData && typeof websiteData === 'object') {
        websiteType = websiteData.websiteType || '';
      }
    } catch (error) {
      console.error('Error parsing website data from localStorage', error);
    }
  }

  const itemsPerPage = 4; // Number of items to display at once
  const [currentIndex, setCurrentIndex] = useState(0);

  const totalItems = filteredOptions.length;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to control button clicks

  // Next button handler
  const handleNextBtn = () => {
    if (isButtonDisabled) return; // Prevent action if button is disabled

    if (containerRef.current && currentIndex + itemsPerPage < totalItems) {
      const scrollAmount = containerRef.current.clientWidth; // Get the current width of the container
      containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setCurrentIndex(prev => prev + itemsPerPage);
      setIsButtonDisabled(true); // Disable button
      setTimeout(() => setIsButtonDisabled(false), 500); // Re-enable after 0.5 seconds
    }
  };

  // Previous button handler
  const handlePrevBtn = () => {
    if (isButtonDisabled) return; // Prevent action if button is disabled

    if (containerRef.current && currentIndex > 0) {
      const scrollAmount = -containerRef.current.clientWidth; // Get the current width of the container
      containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setCurrentIndex(prev => Math.max(prev - itemsPerPage, 0));
      setIsButtonDisabled(true); // Disable button
      setTimeout(() => setIsButtonDisabled(false), 500); // Re-enable after 0.5 seconds
    }
  };


    // Search & business selection states
    const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
    const [customBusinessType, setCustomBusinessType] = useState('');
  // Add these new state variables and ref at the top of your component:

  const [subCurrentIndex, setSubCurrentIndex] = useState(0);
  const subItemsPerPage = 4; // Adjust as needed
  const subContainerRef = useRef(null);

  

  // Define helper functions for checking selected state:
  const isOptionSelected = (name: any) =>
    selectedBusiness && selectedBusiness.name === name;

  const isSubCategorySelected = (subCategory: any) =>
    businessSubCategory === subCategory;

  /* If logged in and have plan web hosting or complete, show a grid of these tools */

  const tools = [
    { name: "Web Hosting", icon: "fas fa-server", url: "web-hosting" },
    { name: "Reporting", icon: "fas fa-chart-line" }, // Defaults to /dashboard/reporting
    { name: "Mail", icon: "fas fa-envelope", url: " " },
    { name: "Calendar", icon: "fas fa-calendar-alt", url: " " },
    { name: "My Business", icon: "fas fa-briefcase" },
    { name: "Task Manager", icon: "fas fa-tasks", url: "tasks" },
  ];

  const [isVisible, setIsVisible] = useState(false);

  const handleTileClick = (tool: any) => {
    const url = tool.url || tool.name.toLowerCase().replace(/\s+/g, "-");
    window.location.href = `https://businessbloc.com/dashboard/${url}`;
  };

  return (
    <>
    <section className={`CreateBusinessWebsite-Loading Step-${currentStep}`}>

    { appLoading == false && websiteStatusLoading == false ? 
      blocWebsiteBuildingSetting == "simple" ? 
        <>
          
        </>
      :
      <>  
      {currentStep === 1 && (
      <div className="CreateBusinessWebsiteContainer">
        <div className={`CreateBusinessWebsiteContainer-Inner ${currentStep === 1 ? 'fade-in' : 'fade-out'}`}>
            <h1>Create your website in<br/>two minutes</h1>
            {/*<h2>What type of website do you want to generate?</h2>*/}
            <div className="Step-One-Section">
              <span><i className="fa-solid fa-arrow-pointer"></i> Please select a business type or search for your desired choice below.</span>
              <div className="SearchBox">
                <input
                  type="text"
                  placeholder="Search for your business type..."
                  value={customNiche}
                  onChange={(e: any) => setCustomNiche(e.target.value)}
                  onKeyDown={handleSearchEnter}
                />
                <button>Search</button>
              </div>

              {/*<ul>
                <li onClick={() => handleOptionClick('Cafe')} className={isOptionSelected('Cafe') ? 'selected' : ''}><i className="fas fa-coffee"></i> Cafe</li>
                <li onClick={() => handleOptionClick('Church')} className={isOptionSelected('Church') ? 'selected' : ''}><i className="fa-solid fa-church"></i> Church</li>
                <li className='option-coming-soon'><i className="fa-solid fa-bolt"></i> Electrician</li>
                <li className='option-coming-soon'><i className="fa-solid fa-scale-balanced"></i> Lawyer</li>
              </ul>*/}
            </div>
        </div>



        { /* Main Business Options */ }
<div className="business-arrow-container">
  <div className="business-type-search-container items-container" ref={containerRef}>
    <ul>
      {filteredOptions.map((option) => (
        <div className="option-container" key={option.name}>
          <li
            onClick={() => !option.comingSoon && handleOptionClick(option)}
            className={
              isOptionSelected(option.name)
                ? 'selected'
                : option.comingSoon
                ? 'option-coming-soon'
                : ''
            }
          >
            <i className={option.icon}></i> {option.name}
          </li>
        </div>
      ))}
    </ul>
  </div>
  <i
    className={`Previous-Business-Arrow fa-solid fa-chevron-left ${
      currentIndex === 0 || isButtonDisabled ? 'disabled' : ''
    }`}
    onClick={handlePrevBtn}
  ></i>
  <i
    className={`Next-Business-Arrow fa-solid fa-chevron-right ${
      currentIndex + itemsPerPage >= totalItems || isButtonDisabled ? 'disabled' : ''
    }`}
    onClick={handleNextBtn}
  ></i>
</div>

{ /* STEP 2: Subcategories */ }
{selectedOption && subCategories.length > 0 && (
  <>
    <div className="business-arrow-container">
      <div className="business-type-search-container items-container" ref={subContainerRef}>
        <ul>
          {subCategories.slice(subCurrentIndex, subCurrentIndex + subItemsPerPage).map((subCategory, index) => (
            <div className="option-container" key={index}>
              <li
                onClick={() => setBusinessSubCategory(subCategory)}
                className={isSubCategorySelected(subCategory) ? 'selected' : ''}
              >
                {subCategory}
              </li>
            </div>
          ))}
        </ul>
      </div>
      <i
        className={`Previous-Business-Arrow fa-solid fa-chevron-left ${
          subCurrentIndex === 0 ? 'disabled' : ''
        }`}
        onClick={() => subCurrentIndex > 0 && setSubCurrentIndex(subCurrentIndex - 1)}
      ></i>
      <i
        className={`Next-Business-Arrow fa-solid fa-chevron-right ${
          subCurrentIndex + subItemsPerPage >= subCategories.length ? 'disabled' : ''
        }`}
        onClick={() =>
          subCurrentIndex + subItemsPerPage < subCategories.length &&
          setSubCurrentIndex(subCurrentIndex + subItemsPerPage)
        }
      ></i>
    </div>
  </>
)}





      </div>
      )}


   {/* STEP 3 */}
   {currentStep === 4 && businessAuth.status == false && (
      <>
      <div className="FBusinessFeature">
        <div className="CreateBusinessWebsite-Inner">
          <div className="CreateBusinessWebsite-InnerHeadingContainer">
            <h3>Create your business website</h3>
            <span>Step 4 /  {totalAmountOfSteps}</span>
          </div>
        </div>
      </div>
      {/* STEP 3 */}
      <div className="CreateBusinessWebsiteContainer">
      <div className={`CreateBusinessWebsiteContainer-Inner ${currentStep === 4 ? 'fade-in' : 'fade-out'}`}>
      <Formik
          onSubmit={registerNewBusiness}
          validationSchema={registerNewBusinessValidationSchema}
          initialValues={initialValues}
      >
      <Form>
          <div className="Create-Your-Business-Bloc-Account">
            <div className="Register-Form-Details">
              <h3>Before we generate your website, we require a few details...</h3>
              <span>Please create your Business Bloc account by filling in your business name, email and password.</span>
            </div>
            <div className="Register-Form-Fields">
              <h3>Create your free account</h3>
              <div className="Field-Container">
                <label>Business name</label>
                <Field type="text" placeholder="Business name" name="business_name" />
                <ErrorMessage className="error-message-span" name="business_name" component="span" />
              </div>
              <div className="Field-Container">
                <label>Email address</label>
                <Field type="text" placeholder="Email" name="business_email"/>
                <ErrorMessage className="error-message-span" name="business_email" component="span" />
                { errorMessage != "" ?
                <div className="Hero-Register-Business-Error-Message">{errorMessage}</div>
              : "" }
              </div>
              <div className="Field-Container">    
                <label>Password</label>   
                <Field type="password" placeholder="Password" name="business_password"/>
                <ErrorMessage className="error-message-span" name="business_password" component="span" />   
              </div>
              <button type="submit" disabled={disabled}>Register account</button>
            </div>
          </div>
        </Form>
      </Formik>
      </div>
      <div className={`button-actions CreateBusinessWebsiteContainer-Inner ${currentStep === 4 ? 'fade-in' : 'fade-out'}`}>
        <button className="Previous-Button" onClick={handlePreviousStep}><span className="button-action-text">Prev</span></button>
      </div>
      </div>
      </>
    )}

    {/* STEP 4 */}
    {(currentStep === 5 && businessAuth.status == true) ? (
      <>
      <div className="FBusinessFeature">
        <div className="CreateBusinessWebsite-Inner">
          <div className="CreateBusinessWebsite-InnerHeadingContainer">
            <h3>Launch your website to the world</h3>
            <span>Step 4 / {totalAmountOfSteps}</span>
          </div>
          <p>View your generated website now! Request edits or regeneration, purchase web hosting once you're ready to launch your website!</p>

        </div>
      </div>
      {/* STEP 4 */}
      <div className="CreateBusinessWebsiteContainer">
      <div className={`CreateBusinessWebsiteContainer-Inner ${currentStep === 5 ? 'fade-in' : 'fade-out'}`}>
        <div className="CreateBusinessWebsitePaymentFormDetails">

          <a className="change-design-settings" onClick={changeDesignSettings}><i className="fa fa-chevron-left"></i> Change design settings</a>


          <div className="select-a-draft-navigator">
  <label htmlFor="draftSelector">
    <i className="fa fa-pen"></i> Select a Draft:
  </label>

  <select
    id="draftSelector"
    onChange={handleDraftSelectChange}
    value={fullListOfDrafts.findIndex((draft: any) => 
      draft.draft_url === selectedDraft?.draft_url &&
      draft.draft_url_screenshot_url?.[0]?.url === selectedDraft?.draft_url_screenshot_url?.[0]?.url
    )}
  >
    {fullListOfDrafts.map((draft: any, index: number) => (
      <option key={draft.id} value={index}>
        Draft {index + 1} - {draft.website_category}
      </option>
    ))}
  </select>
</div>

          { selectedDraft?.draft_url_screenshot_url?.[0]?.url != null ?
          <>
          <img src={process.env.REACT_APP_BLOC_SERVER_URL + selectedDraft.draft_url_screenshot_url?.[0]?.url}/>
          <a href={selectedDraft.draft_url} target="_blank"><i className="fa fa-link"></i> View my draft</a>
          </>
          : 
            <>
               {designErrorMessage != "" ?
                <div className='something-went-wrong'><i className="fa fa-bar-chart"></i>
                  {designErrorMessage}
                </div>
              : 
              <div className='something-went-wrong'><i className="fa-solid fa-face-frown"></i>
                Something went wrong, please regenerate your website.
              </div>
              }
            </>
          }
          <a className="regenerate-button" href={aiDraftWebsiteUrl} onClick={handleDesignGeneration} target="_blank"><i className="fa fa-refresh"></i> Regenerate</a>
          <p>By providing your credit card details, you are agreeing to enroll in our <span className="green-highlight">monthly subscription plan</span> at a cost of $15/CAD per month, starting from this month and continuing on the same day each month until you choose to cancel your subscription.</p>
        </div>
        <div className="CreateBusinessWebsitePaymentForm">
          <>          
          <span className="checkout-item">$15/monthly CAD - Purchase full access to web design builder & on-going website hosting</span>
          <h2>Checkout now</h2>
          <p><i className="fa fa-lock"></i> Payment secured with Stripe and SSL</p>
          { allowCreditCardFieldInput == true ?
          <form onSubmit={handleSubmit}>
            <CardElement />
            <div>{messages}</div> 
            { paymentInProgress == false ?
            <button className="Checkout-Payment-Button" type="submit">Confirm payment</button>
            :
            <button className="Transaction-In-Progress Checkout-Payment-Button disabled" type="submit" disabled={true}>
              <i className="fa-solid fa-circle-notch fa-spin"></i>
              Transaction in progress</button>
            }
          </form> 
          : <div className="establishing-stripe-connection">Establishing secure access to Stripe <i className="fas fa-spinner fa-spin"></i></div> }
          </>
          <p className="terms">By proceeding, you acknowledge that you have read and agree to our Terms of Service and Privacy Policy.</p>
        </div>  
        {/*<button className="Previous-Button" onClick={handlePreviousStep}>Prev</button>*/}

      </div>

      </div>
      </>
    ) : "" }


        {/* STEP 6 */}
        {currentStep === 6 && (
          <>
            <div className="FBusinessFeature">
              <div className="CreateBusinessWebsite-Inner">
                <div className="CreateBusinessWebsite-InnerHeadingContainer">
                  <h3>Website generation in progress</h3>
                  <span>All steps complete</span>
                </div>
              </div>
            </div>
            {/* STEP 6 */}
            <div className="CreateBusinessWebsiteContainer">
              <div className={`CreateBusinessWebsiteContainer-Inner ${currentStep === 6 ? 'fade-in' : 'fade-out'}`}>
                <div className="CreateBusinessWebsiteGeneratingStatus">
                  <p>Completion typically takes <b>10 seconds</b>, please check back shortly to see your new site.</p>
                </div>
                <div className="CreateBusinessWebsiteGeneratingDetails">
                  <i className="fa-solid fa-cog fa-spin" ></i>
                  <h3>Generating website design assets</h3>
                </div>
              </div>
              <video autoPlay={true} loop={true} className="generating-website-design-image" src={videoFile}/>
            </div>
          </>
        )}


{currentStep === 7 && (

    <div className="CreateBusinessWebsiteContainer">
    <div className={`CreateBusinessWebsiteContainer-Inner Active-Plan ${currentStep === 7 ? 'fade-in' : 'fade-out'}`}>
    
    <div className="CreateBusinessWebsitePaymentFormDetails">

    <a className="change-design-settings" onClick={changeDesignSettings}><i className="fa fa-chevron-left"></i> Change design settings</a>


    <div className="select-a-draft-navigator">
<label htmlFor="draftSelector">
<i className="fa fa-pen"></i> Select a Draft:
</label>

<select
id="draftSelector"
onChange={handleDraftSelectChange}
value={fullListOfDrafts.findIndex((draft: any) => 
draft.draft_url === selectedDraft?.draft_url &&
draft.draft_url_screenshot_url?.[0]?.url === selectedDraft?.draft_url_screenshot_url?.[0]?.url
)}
>
{fullListOfDrafts.map((draft: any, index: number) => (
<option key={draft.id} value={index}>
  Draft {index + 1} - {draft.website_category}
</option>
))}
</select>
</div>

    { selectedDraft?.draft_url_screenshot_url?.[0]?.url != null ?
    <>
    <img src={process.env.REACT_APP_BLOC_SERVER_URL + selectedDraft.draft_url_screenshot_url?.[0]?.url}/>
    <a href={selectedDraft.draft_url} target="_blank"><i className="fa fa-link"></i> View my draft</a>
    </>
    : 
      <>
         {designErrorMessage != "" ?
          <div className='something-went-wrong'><i className="fa fa-bar-chart"></i>
            {designErrorMessage}
          </div>
        : 
        <div className='something-went-wrong'><i className="fa-solid fa-face-frown"></i>
          Something went wrong, please regenerate your website.
        </div>
        }
      </>
    }
    <a className="regenerate-button" href={aiDraftWebsiteUrl} onClick={handleDesignGeneration} target="_blank"><i className="fa fa-refresh"></i> Regenerate</a>
  </div>
    
    <div className="CreateBusinessWebsitePaymentForm">
      <h2><i className="fa fa-hammer"></i> Let's get building</h2>

    <div className="grid-container">
        {tools.map((tool, index) => (
          <div
            key={tool.name}
            className={`tile ${isVisible ? `fade-in delay-${index}` : ""}`}
            onClick={() => handleTileClick(tool)}
          >
            <i className={`${tool.icon} icon`} />
            <span className="tile-text">{tool.name}</span>
          </div>
        ))}
        {/* Add empty tiles to maintain 3x3 layout */}
        {[...Array(9 - tools.length)].map((_, index) => (
          <div key={`empty-${index}`} className="tile empty"></div>
        ))}
      </div>
      <style>
        {`
        /* Main container styles */
        .create-business-website-payment-form {
          padding: 20px;
          background-color: #f7f7f7;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          max-width: 600px;
          margin: 0 auto;
        }

        /* Grid layout */
        .grid-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 15px;
        }

        /* Tile styles */
        .tile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          background: #e4ffb4;
          border-radius: 8px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }

        .tile:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .tile:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        }

        /* Fade-in animations */
        .fade-in {
          opacity: 1;
          transform: translateY(0);
        }

        .delay-0 {
          transition-delay: 0s;
        }
        .delay-1 {
          transition-delay: 0.2s;
        }
        .delay-2 {
          transition-delay: 0.4s;
        }
        .delay-3 {
          transition-delay: 0.6s;
        }
        .delay-4 {
          transition-delay: 0.8s;
        }
        .delay-5 {
          transition-delay: 1s;
        }

        /* Icon styles */
        .icon {
          font-size: 2.5rem;
          color: #0078d4;
          margin-bottom: 10px;
        }

        /* Tile text styles */
        .grid-container .tile-text {
          font-size: 18px;
          font-weight: bold;
          color: #000;
          text-align: center;
          box-sizing: border-box;
        }

        /* Placeholder for empty tiles */
        .tile.empty {
          background: transparent;
          box-shadow: none;
          pointer-events: none;
        }
        `}
      </style>



    </div> 
  </div>

  </div>
  )} 
  </> 



    : 
      <>
      <div className="ai-website-generation-loading">
        <i className="fas fa-spinner fa-spin"></i>
        <span>Generating website builder...</span>
      </div>
      </>
    }


  </section>

  </>
  );
};

export default SimpleDesign;
