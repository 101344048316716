import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../PageHeader/PageHeader'
import './Directory.css'
import './Popup.css'

import heroImage from '../../assets/images/referral-program/business-bloc-referral-program.png';
import businessDirectoryMain from '../../assets/images/business-directory/business-directory.jpeg';

import '../PageHeader/PageHeader.css'
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikValues, FormikState, FieldInputProps } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import BusinessItem from './BusinessItem';
import { send } from 'process';

interface BusinessDetailProps {
  business_claimed_id: string;
}

interface BusinessItemProps {
  name: string;
  category: string;
  image: string;
  description: string;
  address: string;
  phoneNumber: string;
}

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  title: string;
  showContact: boolean;
  showHoursOfOperation: any;
  directory_business_id: any;
  hoursOfOperationData: any;
}

type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
  business_website_url: string
}
const initialValues: FormValues = {
  business_name: '',
  business_email: '',
  business_password: '',
  business_website_url: ''
}


interface Params {
  business_unique_id: string
}

type CategoryIcons = {
  plumbing: string;
  electrician: string;
  restaurant: string;
  automotive: string;
  clothing: string;
  home_improvement: string;
  technology: string;
  healthcare: string;
  education: string;
  entertainment: string;
  fitness: string;
  beauty: string;
  finance: string;
  real_estate: string;
  gas_station: string;
  travel: string;
  photography: string;
  legal_services: string;
  marketing: string;
  insurance: string;
  event_planning: string;
  art_and_design: string;
  pets: string;
  home_services: string;
  other: string;
};


const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Contact email is required'),
  phoneNumber: Yup.string(),
  rating: Yup.number().min(1, 'Rating must be at least 1').max(5, 'Rating must be at most 5'),
  message: Yup.string().required('Message is required'),
});
const BusinessDetail: React.FC = () => {
  const { business_unique_id } = useParams();
  const [startIndex, setStartIndex] = useState(0);
  const [toThisBusiness, setToThisBusiness] = useState<any>(undefined);
  const [ToThisBusinessDirectory, setToThisBusinessDirectory] = useState<any>(undefined);
  const [toThisBusinessCategory, setToThisBusinessCategory] = useState<any>(undefined);
  const [toThisBusinessAddress, setToThisBusinessAddress] = useState<any>(undefined);
  const [toThisBusinessSocialMedia, setToThisBusinessSocialMedia] = useState<any>(undefined);
  const [toThisBusinessImages, setToThisBusinessImages] = useState<any>(undefined);
  const [toThisBusinessHeroImage, setToThisBusinessHeroImage] = useState<any>(undefined);
  const [toThisBusinessReviews, setToThisBusinessReviews] = useState<any>(undefined);
  const [toThisBusinessHoursOfOperation, setToThisBusinessHoursOfOperation] = useState<any>([]);


  const [averageRating, setAverageRating] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);

  
  const [toThisBusinessGoogleMapAddress, setToThisBusinessGoogleMapAddress] = useState<any>(undefined);
  const totalImages = 8; // 8 images to render
  const [transformValue, setTransformValue] = useState<number>(0);
  const [loadPageAssets, setLoadPageAssets] = useState(false);

  const handleLeftArrowClick = () => {
    if (transformValue > 0) {
      setTransformValue((prev) => Math.max(prev - 1, 0));
    }
  };

  const handleRightArrowClick = () => {
    if (transformValue < 4) {
      setTransformValue((prev) => Math.min(prev + 1));
    }
  };

  const business_claimed_id = new URLSearchParams(window.location.search).get('business_claimed_id');
  let businessBlocAuthToken = localStorage.getItem("business_bloc_token")

  let params: Params = {
    business_unique_id: business_unique_id || "g"
  }
  

  async function findSupportTickets() {
    await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + '/business/directory/retrieve-listing', {
      params
    }).then((retrieveSupportTickets: any) => {
      if(retrieveSupportTickets.data.listing.length != 0){
        setToThisBusiness(retrieveSupportTickets.data.listing[0])
        let businessDirectory = retrieveSupportTickets.data.listing[0].business_directory
        let businessAddress = retrieveSupportTickets.data.listing[0].business_address
        let businessSocialMedia = retrieveSupportTickets.data.listing[0].business_social_media
        let businessReviews = retrieveSupportTickets.data.reviews[0]
        let businessHoursOfOperations = retrieveSupportTickets.data.listing[0].business_hours_of_operation
        console.log(JSON.stringify(retrieveSupportTickets.data.reviews[0]))
        let parsedBusinessDirectory = businessDirectory
        let parsedAddress = businessAddress
        let parsedSocialMedia = businessSocialMedia

        // Reviews
        setToThisBusinessReviews(businessReviews)
  
        // Assuming retrieveSupportTickets.data.review contains the reviews data
        const reviewsData = retrieveSupportTickets.data.reviews[0];
        // Reviews
        //alert(JSON.stringify(retrieveSupportTickets.data))
        setToThisBusinessReviews(reviewsData);
        // Calculate average rating and update state
        //alert(JSON.stringify(reviewsData))
        const calculateAverageRating = () => {
          if (!retrieveSupportTickets.data.reviews) return 0;
          try {
          const reviews = JSON.parse(retrieveSupportTickets.data.reviews[0].review_history);
          const totalRatings = reviews.reduce((sum: any, review: any) => sum + review.rating, 0);
          const averageRating = totalRatings / reviews.length;
          setTotalRatings(reviews.length)
          return averageRating;
          } catch(e) {
            console.log(e)
            return 0
          }
        };


        // Use the callback form of setState to ensure proper ordering
        setAverageRating(prevAverageRating => calculateAverageRating());


        
        // Hours Of Operation
        setToThisBusinessHoursOfOperation(businessHoursOfOperations)

        // Images
        setToThisBusinessImages(parsedBusinessDirectory[0].business_images)
        const urlToSave =  process.env.REACT_APP_BLOC_SERVER_URL + parsedBusinessDirectory[0].hero_image.url
        setToThisBusinessHeroImage(urlToSave);
        
        // Categories
        setToThisBusinessCategory(parsedBusinessDirectory[0].business_categories[0].category_name.replaceAll('-', ' '))
                        
        // Address
        const streetAddressObject = parsedAddress.find((obj: any) => obj.street_address !== undefined);
        setToThisBusinessAddress(streetAddressObject.street_address)
        const cityObject = parsedAddress.find((obj: any) => obj.city !== undefined);
        const stateObject = parsedAddress.find((obj: any) => obj.state !== undefined);
        const countryObject = parsedAddress.find((obj: any) => obj.country !== undefined);
        setToThisBusinessGoogleMapAddress(streetAddressObject.street_address + ", " + cityObject.city + ", " + stateObject.state + ", " + countryObject.country)
        
        // Social media
        setToThisBusinessSocialMedia(parsedSocialMedia)
      }
    })
  }

  const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(toThisBusinessGoogleMapAddress)}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`;

  React.useEffect(() => {
    findSupportTickets().then(() => {
      setLoadPageAssets(true)
    })
    //const businessPhone = toThisBusiness
  }, [])


 function getStarIcons(rating: number) {
  let starIcons: string = '';
  for (let i = 1; i <= 5; i++) {
    starIcons += `<i class='fa fa-star' style='color: ${
      i <= rating ? 'gold' : 'grey'
    }'></i>`;
  }
  return starIcons;
}


const renderStars = (averageRating:any) => {
  const fullStars = Math.floor(averageRating);
  const halfStar = averageRating - fullStars >= 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  const stars = [];

  // Render full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<i key={i} className="fa fa-star gold"></i>);
  }

  // Render half star
  if (halfStar) {
    stars.push(<i key="half" className="fa fa-star-half-alt gold"></i>);
  }

  // Render empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(<i key={`empty-${i}`} className="fa fa-star dark-gray"></i>);
  }

  return stars;
};

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 10);
  };

  const navigate = useNavigate();

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showHoursOfOperations, setShowHoursOfOperations] = useState(false);

  const [showBusinessHoursOfOperations, setShowBusinessHoursOfOperations] = useState<any>([]);

  const handleButtonClick = (title: any, showContact: boolean, showHoursOfOperation: boolean) => {
    setPopupTitle(title)
    setPopupOpen(true);
    setShowContactDetails(showContact)
    setShowHoursOfOperations(showHoursOfOperation)
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setShowBusinessHoursOfOperations(false)
  };

  const handlePopupSubmit = (values: any) => {
    alert(JSON.stringify(values))
    //let emailContent = draftToHtml(convertToRaw(newEmailContent.getCurrentContent()))
    let emailContent = values.message
    let mailObject
    let mailHistory
    let sendingTo = { "type": "business", "id": toThisBusiness.id }
    let retrieveContentTemplate
    let mailTitle
    let additionalFunction
    if(values.rating != undefined){
      additionalFunction = JSON.stringify({type:'directory-review', data: values })
      mailTitle = "rating"
      retrieveContentTemplate = `
      <div class='lead-email'>
        <h2>Your business has been reviewed!</h2>
        <div class='rating-container'>
          <h4>You recieved a ${values.rating} rating!</h4>
          ${getStarIcons(values.rating)}
        </div>
        <div class='data'>${emailContent}</div>
        <h4>First name</h4>
        <div class='data'>${values.firstName}</div>
        <h4>Last name</h4>
        <div class='data'>${values.lastName}</div>
        <h4>Email address</h4>
        <div class='data'>${values.email}</div>
        <h4>Phone number</h4>
        <div class='data'>${values.phoneNumber}</div>
      </div>
      <div class="review-disclaimer">This review is currently under review to verify authenticity and complies with the terms and conditions of Business Bloc.</div>
    `;
      } else {
      mailTitle = "lead"
      retrieveContentTemplate = "<div class='lead-email'><h2>Inquiry details</h2><div class='data'>" + emailContent + "</div><h4>First name</h4><div class='data'>" + values.firstName + "</div><h4>Last name</h4><div class='data'>" + values.lastName + "</div></h4><h4>Email address</h4><div class='data'>" + values.email + "</div><h4>Phone number</h4><div class='data'>" + values.phoneNumber + "</div></div>";
      additionalFunction = JSON.stringify({type: 'create-lead', data: values})
    }

    // Mail History
    mailHistory = {
      sender: { "type": "business", "id": toThisBusiness.id },
      sender_name: values.business_name,
      mail_to: JSON.stringify(sendingTo),
      //mail_title: "New lead from business directory",
      mail_title: mailTitle,
      mail_content: retrieveContentTemplate
    }

    // Mail Object
    mailObject = {
      mail_type: "internal",
      mail_history: mailHistory,
      message_type: "new-mail",
      additional_function: additionalFunction
    }

    // Create new bloc mail
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/bloc-mail/create-new-bloc-mail', mailObject, {
      headers: {
        "x-access-token": businessBlocAuthToken,
      },
    }).then( async(newMailItem) => {
      alert(newMailItem)
      /*var currentToCompare = [...liveInbox];
      var newMailItemData = newMailItem.data;
      let mail_history_item = JSON.stringify(newMailItemData.mail_history);
      let newObject = {
        id: newMailItemData.id,
        mail_type: newMailItemData.mail_type,
        mail_history: mail_history_item
      }
      currentToCompare.push(newObject);
      //alert(JSON.stringify(currentToCompare))
      setLiveInbox(currentToCompare);
      setMailItem(newObject);*/
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: smooth scrolling animation
    });
    // Handle the form submission logic here
    console.log('Form submitted with values:', values);
  };

const cleanedUrl = toThisBusinessHeroImage
  ? toThisBusinessHeroImage.replace(/\\/g, '/')
  : '';

  const categoryIcons: CategoryIcons = {
    plumbing: 'fa fa-wrench',
    electrician: 'fa fa-bolt',
    restaurant: 'fa fa-cutlery',
    automotive: 'fa fa-car',
    clothing: 'fa fa-shirt',
    home_improvement: 'fa fa-tools',
    technology: 'fa fa-laptop',
    healthcare: 'fa fa-medkit',
    education: 'fa fa-graduation-cap',
    entertainment: 'fa fa-film',
    fitness: 'fa fa-dumbbell',
    beauty: 'fa fa-makeup-brush',
    finance: 'fa fa-dollar-sign',
    real_estate: 'fa fa-home',
    gas_station: 'fa fa-gas-pump',
    travel: 'fa fa-plane',
    photography: 'fa fa-camera',
    legal_services: 'fa fa-balance-scale',
    marketing: 'fa fa-bullhorn',
    insurance: 'fa fa-shield-alt',
    event_planning: 'fa fa-calendar',
    art_and_design: 'fa fa-paint-brush',
    pets: 'fa fa-paw',
    home_services: 'fa fa-home',
    other: 'fa fa-question',
  };

  // Function to get the Font Awesome icon for a given category
  const getCategoryIcon = (category: keyof CategoryIcons): string => {
    return categoryIcons[category] || 'fa fa-building'; // Default icon if category not found
  };

  // Your JSX code
  const selectedCategory = toThisBusinessCategory || ""; // Ensure a valid category is selected
  const selectedIconClass = getCategoryIcon(selectedCategory);
  


  return (
    <>
    { toThisBusiness != undefined ?

    <>
    {/* BUTTON POPUP */}
    <Popup
      isOpen={isPopupOpen}
      onClose={handleClosePopup}
      onSubmit={handlePopupSubmit}
      title={popupTitle} // Adjust the title as needed
      showContact={showContactDetails}
      directory_business_id={toThisBusiness.id}
      showHoursOfOperation={showHoursOfOperations}
      hoursOfOperationData={toThisBusinessHoursOfOperation}
    />
    {/* BUTTON POPUP */}
    <div className="Business-Directory-Detail-Page">    
      <section className="Page-Header Business-Directory" style={{ backgroundImage: `linear-gradient(to right, rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88) 5%, #620086), url(${cleanedUrl}), url(${cleanedUrl})` }}>
        <div className="Page-Header-Main-Section-Full-Width">
        <div className="Page-Header-content-full-width">
          <div className="Page-Header-content-70">
              <h1>{toThisBusiness.business_name != null ? toThisBusiness.business_name : "No business name"}</h1>
              <h2>
                { /* CATEGORY & ICON */ }

                <i className={selectedIconClass}></i>
                {
                  selectedCategory !== "" ? (
                    <span className="Category-Name">{selectedCategory}</span>
                  ) : (
                    <>
                     <span className="Category-Name">No business category</span>
                    </>
                  )
                }

              </h2>
              {toThisBusiness.business_rank != "unclaimed" ?
                <span className="Verified-Badge"><i className="fa fa-check"></i> Verified</span>
              : "" }
              <div className="Contact-Details">
                <span><i className="fa fa-phone"></i> {toThisBusiness.business_phone_number}</span>
                <span>
                  <i className="fa fa-map"></i> 
                  { toThisBusinessAddress != undefined ?
                  <>
                    <span className="Category-Name">{toThisBusinessAddress}</span>
                  </>
                  : 
                    <span className="Category-Name">No public address</span>
                  }
                </span>
              </div>
              <div className="Action-Buttons">
                <a onClick={() => ( handleButtonClick("Send Message", true, false))}>Send a message</a>
                {/*<a onClick={() => ( handleButtonClick("Send Message", true))}>Send a message</a>*/}
              </div>
          </div>
          <div className="Page-Header-content-30">
            <div className="Business-Header-Highlights">
              <div className="Business-Reviews">
                <div className="Business-Reviews-Stars">
                  {renderStars(averageRating)}
                </div>
                <span className="Average-Rating-Label">{averageRating.toFixed(2)}</span>
                <div className="Business-Reviews-Count">
                  ({totalRatings} reviews) <span className="Add-A-Review" onClick={() => ( handleButtonClick("Post Review", true, false))}>Add a review</span>
                </div>
              </div>
              {/*<div className="Business-Open-Status">
                Open
              </div>*/}
              {toThisBusinessHoursOfOperation != "{}" && toThisBusinessHoursOfOperation != undefined ?
              <>
                {/*<a onClick={() => (handleButtonClick("Hours Of Operation", true, true))} className="Business-Hours-Of-Operation">
                  <i className="fa fa-clock"></i> View hours of operation
            </a>*/}
              </> 
              :
                null
              }
              { toThisBusiness.business_website_url != null ?
              <a target="_blank" className="Business-View-Website" href={toThisBusiness.business_website_url}>
                View website <i className="fa fa-arrow-right"></i>
              </a>
              : <span className="Business-No-Website-URL-Found"><i className="fa fa-link"></i> No website URL found</span> }
            </div>
          </div>
          </div>
        </div>
      </section>
      <section className="Page Business-Directory-Detail">
        <div className="Page-content">
          <div className="business-detail">
          {toThisBusinessImages.length > 0 ?

          <div className="carousel-container">
            <div className="business-images-container">
          <div className="business-images" style={{ transform: `translateX(${transformValue * -25}%)` }}>        
            {Array.from({ length: totalImages }).map((_, index) => {
              const imageIndex = (startIndex + index) % toThisBusinessImages.length;
              const imageUrl = process.env.REACT_APP_BLOC_SERVER_URL + toThisBusinessImages[imageIndex]?.url; // Access the 'url' property

              return (
                <div key={index} className="image-container">
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      className="carousel-image"
                    />
                  )}
                </div>
              );
            })}
          </div>
      </div>
      <div className="arrow-container">
        <a className={`arrow left ${transformValue <= 0 ? 'disabled' : ''}`} onClick={handleLeftArrowClick}>
          <i className="fa fa-arrow-left"></i>
        </a>
        <a
          className={`arrow right ${transformValue > 3 ? 'disabled' : ''}`}
          onClick={handleRightArrowClick}
        >
          <i className="fa fa-arrow-right"></i>
        </a>
      </div>
      <a className="submit-your-own image" onClick={() => ''}><span className="text">View more</span> <i className="fa fa-plus"></i></a>
      
    </div>
      : "" }

            <div className="business-detail-inner">
              <div className="business-description">
                <h2>Overview</h2>
                {toThisBusiness.business_description ? (
                  <p>{toThisBusiness.business_description}</p>
                ) : (
                  <p>No description found</p>
                )}
              </div>
              { toThisBusinessGoogleMapAddress != undefined ?
                  <>
              <div className="business-location">
                <h2><i className="fa fa-map"></i> Business location</h2>
                <iframe 
                  frameBorder="0" 
                  src={embedUrl}                
                />
              </div>
              </> 
              : "" }
            </div>
          </div>
          <div className="business-list-sidebar">
            <div className="business-list-social-media-links">
              <h2>Social media</h2>
              <div className="business-list-social-media-links-inner">
              
              {toThisBusinessSocialMedia && toThisBusinessSocialMedia[0] ?
              <>

              {toThisBusinessSocialMedia[0].facebook && (
                <div className="social-media-item">
                  <a target="_blank" href={toThisBusinessSocialMedia[0].facebook}>
                    <i className="fab fa-facebook-square"></i>
                    <span>Facebook</span>
                  </a>
                </div>
              )}

              {toThisBusinessSocialMedia[0].instagram && (
                <div className="social-media-item">
                  <a target="_blank" href={toThisBusinessSocialMedia[0].instagram}>
                    <i className="fab fa-instagram"></i>
                    <span>Instagram</span>
                  </a>
                </div>
              )}

              {toThisBusinessSocialMedia[0].linkedin && (
                <div className="social-media-item">
                  <a target="_blank" href={toThisBusinessSocialMedia[0].linkedin}>
                    <i className="fab fa-linkedin"></i>
                    <span>Linkedin</span>
                  </a>
                </div>
              )}
</>
              : <span className="no-social-media-profiles">No social media profiles found.</span> }

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
  : 
  
  
  <div className="Business-Directory-Detail-Page">    
  <section className="Page-Header Business-Directory" style={{ backgroundImage: `linear-gradient(to right, rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88) 5%, #620086), url(${cleanedUrl}), url(${cleanedUrl})` }}>
    <div className="Page-Header-Main-Section-Full-Width">
    <div className="Page-Header-content-full-width">
      <div className="Page-Header-content-70">
          <h1>Loading...</h1>
          <h2>Loading...</h2>
          <div className="Contact-Details">
            <span><i className="fa fa-phone"></i> Loading...</span>
            <span>
              <i className="fa fa-map"></i> 
              <span className="Category-Name">Loading...</span>
            </span>
          </div>
          <div className="Action-Buttons">
            <a onClick={() => ( handleButtonClick("Send Message", true, false))}>Send a message</a>
            {/*<a onClick={() => ( handleButtonClick("Send Message", true))}>Send a message</a>*/}
          </div>
      </div>
      <div className="Page-Header-content-30">
        <div className="Business-Header-Highlights">
          <div className="Business-Reviews">
            <div className="Business-Reviews-Stars">
              {renderStars(averageRating)}
            </div>
            <span className="Average-Rating-Label">{averageRating.toFixed(2)}</span>
            <div className="Business-Reviews-Count">
              ({totalRatings} reviews) <span className="Add-A-Review" onClick={() => ( handleButtonClick("Post Review", true, false))}>Add a review</span>
            </div>
          </div>
          {/*<div className="Business-Open-Status">
            Open
          </div>*/}
          {toThisBusinessHoursOfOperation != "{}" && toThisBusinessHoursOfOperation != undefined ?
          <>
            {/*<a onClick={() => (handleButtonClick("Hours Of Operation", true, true))} className="Business-Hours-Of-Operation">
              <i className="fa fa-clock"></i> View hours of operation
        </a>*/}
          </> 
          :
            null
          }

          <span className="Business-No-Website-URL-Found"><i className="fa fa-link"></i> Loading...</span>
        </div>
      </div>
      </div>
    </div>
  </section>
  <section className="Page Business-Directory-Detail">
    <div className="Page-content">
      <div className="business-detail">


        <div className="business-detail-inner">
          <div className="business-description">
            <h2>Overview</h2>
            <p>Loading description</p>
          </div>

        </div>
      </div>
      <div className="business-list-sidebar">
        <div className="business-list-social-media-links">
          <h2>Social media</h2>
          <div className="business-list-social-media-links-inner">
          
          <span className="no-social-media-profiles">Loading...</span>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
  
  
  
  
  }
  </>
  );
};

interface StarRatingFormFieldProps {
  field: FieldInputProps<number>;
  form: FormikValues;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, onSubmit, title, showContact, directory_business_id, showHoursOfOperation, hoursOfOperationData }) => {
  let parsedHoursOfOperation: any;
  if (hoursOfOperationData.length > 0) {
    parsedHoursOfOperation = JSON.parse(hoursOfOperationData);
  }
  return (
    <>
      {isOpen && (
        <div className="popup-container">
          <div className="popup">
            { showHoursOfOperation == true ?
            <>
            <img className="business-display-logo" src="https://images.pexels.com/photos/6039252/pexels-photo-6039252.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"/>
            <h2><i className="fa fa-calendar"></i> {title}</h2>
            {Object.keys(parsedHoursOfOperation).map((day: string, index: number) => (
              <div className="operation-item" key={index}>
                <div className="operation-day">{day.charAt(0).toUpperCase() + day.slice(1)}:</div>
                <p className="operation-open"><strong>Open:</strong> {parsedHoursOfOperation[day].open || 'Closed'}</p>
                <p className="operation-closed"><strong>Close:</strong> {parsedHoursOfOperation[day].close || 'Closed'}</p>
              </div>
            ))}
            </>
            :
            <>
            <img className="business-display-logo" src="https://images.pexels.com/photos/6039252/pexels-photo-6039252.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"/>
            <h2>{title}</h2>
            <Formik
              initialValues={{
                business_id: directory_business_id,
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                rating: undefined,
                message: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmit(values);
                onClose();
              }}
            >
              
              <Form>
                <div className="form-group two-columns">
                  <div className="field-container">
                    <label htmlFor="firstName">First Name</label>
                    <Field type="text" id="firstName" name="firstName" />
                    <ErrorMessage name="firstName" component="div" className="error-message" />
                  </div>
                  <div className="field-container">
                    <label htmlFor="lastName">Last Name</label>
                    <Field type="text" id="lastName" name="lastName" />
                    <ErrorMessage name="lastName" component="div" className="error-message" />
                  </div>
                </div>

                {(showContact) && (
                  <>
                  <div className="form-group two-columns">
                      <div className="field-container">
                        <label htmlFor="email">Email</label>
                        <Field type="text" id="email" name="email" />
                        <ErrorMessage name="email" component="div" className="error-message" />
                      </div>
                      <div className="field-container">
                        <label htmlFor="phoneNumber">Phone Number <span className="optional-tag">(Optional)</span></label>
                        <Field type="text" id="phoneNumber" name="phoneNumber" />
                      </div>
                    </div>
                  </>
                )}

                {title === 'Post Review' && (
                  <>
                  <div className="form-group rating-column">
                    <div className="field-container">
                      <div className="rating-details">
                        <label>Rating:</label>
                        <span>Please select a star rating from 1 - 5.</span>
                      </div>
                      <Field name="rating">
                      {({ field, form }: StarRatingFormFieldProps) => (
                        <StarRatingInput
                          onChange={(value: number) => form.setFieldValue(field.name, value)}
                        />
                      )}
                      </Field>
                      <ErrorMessage name="rating" component="div" />
                    </div>
                  </div>

                  <div className="form-group hidden">
                    <label htmlFor="rating">Rating (1-5)</label>
                    <Field type="number" id="rating" name="rating" />
                    <ErrorMessage name="rating" component="div" className="error-message" />
                  </div>

                  </>
                )}

                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <Field as="textarea" id="message" name="message" />
                  <ErrorMessage name="message" component="div" className="error-message" />
                </div>

                <div className="form-group">
                  <button type="submit">Send message</button>
                </div>
              </Form>
            </Formik>
            </>
            }
            <i className="fa fa-close close-button" onClick={onClose}></i>
          </div>
        </div>
      
      )}
    </>
  );
};

interface StarRatingProps {
  onChange: (rating: number) => void;
}

const Star: React.FC<{ filled: boolean, onClick: () => void, onMouseEnter: () => void }> = ({ filled, onClick, onMouseEnter }) => (
  <i
    className={`fa fa-star${filled ? ' filled' : ''}`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
  />
);

const StarRatingInput: React.FC<StarRatingProps> = ({ onChange }) => {
  const [rating, setRating] = useState<number | null>(null);

  const handleStarHover = (hoveredRating: number) => {
    // Remove this line to prevent hover effect
    // setRating(hoveredRating);
  };

  const handleStarClick = (selectedRating: number) => {
    setRating(selectedRating);
    onChange(selectedRating);
  };

  const renderStars = () => {
    const stars: JSX.Element[] = [];
    const maxRating = 5;

    for (let i = 1; i <= maxRating; i++) {
      const isFilled = i <= (rating || 0);
      stars.push(
        <Star
          key={i}
          filled={isFilled}
          onClick={() => handleStarClick(i)}
          onMouseEnter={() => handleStarHover(i)}
        />
      );
    }

    return stars;
  };

  return (
    <div className="star-rating">
      {renderStars()}
    </div>
  );
};


export default BusinessDetail;