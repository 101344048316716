import React from 'react';
import Pricing from '../Pricing/Pricing'
import PageHeader from '../PageHeader/PageHeader'
import heroImage from '../../assets/images/pricing/business-bloc-pricing.png';


const Services: React.FC<{ businessAuth: any }> = ({ businessAuth }) => {
  return (
    <>
      <PageHeader pageTitle="Pricing" pageImage={heroImage}   pageDescription="Find the ideal monthly rate that suits your business type, enabling you to leverage our comprehensive suite of digital services effortlessly." template=""/>
      <Pricing businessAuth={businessAuth} />
    </>
  );
};

export default Services;
