import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../PageHeader/PageHeader';
import './About.css';
import heroImage from '../../assets/images/about/welcome-to-business-bloc.webp';

const BusinessDashboard: React.FC = () => {
  return (
    <div className="About-page">
      {/* SEO Metadata */}
      <Helmet>
        <title>About Us - Business Bloc</title>
        <meta name="description" content="Learn about Business Bloc and how we help businesses enhance their digital presence." />
        <meta property="og:title" content="About Business Bloc" />
        <meta property="og:description" content="Helping businesses elevate their digital assets with AI-powered solutions." />
        <meta property="og:image" content={heroImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* Page Content */}
      <PageHeader 
        pageTitle="About Business Bloc" 
        pageImage={heroImage} 
        pageDescription="Helping businesses elevate their digital assets." 
        template=""
      />

      <section className="Page">
        <div className="Page-content">
          <div className="Page-content-inner">
            <div className="About-info">
              <h2>Welcome to the Bloc</h2>
              <p>At our core, we are dedicated to providing businesses with a comprehensive suite of digital solutions...</p>
              
              <h2>What We Offer</h2>
              <p><strong>1. Business Dashboard:</strong> A centralized hub for all website maintenance requests...</p>
              <p><strong>2. SEO Article Generation:</strong> High-quality content generation to drive organic traffic...</p>
              <p><strong>3. State-of-the-Art Web Hosting:</strong> Reliable and secure hosting services...</p>

              <h2>Additional Features</h2>
              <p>As we continue to innovate, we are integrating advanced analytics capabilities...</p>

              <h2>Our Vision</h2>
              <p>Our ultimate vision is to cultivate a thriving community of businesses...</p>

              <h2>Collaboration and Rewards</h2>
              <p>Additionally, we are excited to introduce a <strong>Referral System</strong> that rewards businesses...</p>

              <h2>Why Choose Us</h2>
              <p>At our core, we offer simplicity, convenience, and an all-in-one solution...</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BusinessDashboard;
