import React from 'react';

import './HowItWorks.css';
import heroImage from '../../assets/images/home/business-bloc-how-it-works.png';

// Import images

const Home: React.FC = () => {
  return (
    <section className="How-it-works">
    <div className="How-it-works-content">
    <div className="How-it-works-explaination">
      <div className="How-it-works-explaination-content">
        <h2>What is Business Bloc?</h2>
        <h3>A simple, streamlined process that takes less than 5 minutes</h3>
        <div className="divider"/>
        <p>Get the digital services your business needs—fast.</p>
        <p>Business Bloc is your all-in-one solution for web design, branding, SEO, content creation, and digital support. No more juggling agencies or unpredictable costs—just seamless, high-quality services under one roof.</p>
        <p>Need a new website, fresh branding, or a better way to reach customers? Our streamlined system makes it easy to request updates or start new projects in just a few clicks.</p>
        <p>Let’s simplify success. <strong>Get started today.</strong></p>
      </div>
    </div>
    <div className="How-it-works-explaination">
      <div className="How-it-works-explaination-content">
          {/*<iframe width="420" height="450"
            src="https://www.youtube.com/embed/ScMzIvxBSi4">
          </iframe> */}
          <img src={heroImage}/>
      </div>
    </div>
    </div>
  </section>
  );
};

export default Home;
